import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';
import Styles from './NewCredits.module.less';
import BaseBox from './BaseBox';
import ABTestBoxContainer from './ABTestBoxContainer';
import TeamBox from './TeamBox';
import CloseButton from '../../common/CloseButton/CloseButton';

const baseUrl = 'https://resource.flexclip.com/app/subscription/img/';

export default function NewCredits(props) {
    const { purchase = [], getUserInfo = () => {}, changeCheckedCredits, useNewCreditModalABtest } = props;
    const dispatch = useDispatch();
    const [currentBox, setCurrentBox] = useState(null);
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const isTeamOwner = useSelector(state => state.mainReducer.isTeamOwner);
    const teamOwnerTid = useSelector(state => state.mainReducer.teamOwnerTid);
    const checkedPurchase = creditsPayData.index;

    const chooseCreditsAndToPayPanel = () => {
        if (typeof checkedPurchase !== 'number') {
            return;
        }
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        getUserInfo && getUserInfo();
    };

    const purchaseItemClick = index => {
        if (checkedPurchase === index) {
            return;
        }
        changeCheckedCredits(index);
    };

    const handleClose = () => {
        dispatch(mainAction.closeSubscribeView());
        dispatch(mainAction.openOrCloseCreditsPayPanel(false));
        dispatch(mainAction.changeBuyCreditsSuccessPage(false));
    };

    const getBox = () => {
        if (currentBox === 'team') {
            return (
                <TeamBox
                    purchase={purchase}
                    checkedPurchase={checkedPurchase}
                    purchaseItemClick={purchaseItemClick}
                    baseUrl={baseUrl}
                    chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                    isTeamOwner={isTeamOwner}
                    teamOwnerTid={teamOwnerTid}
                    handleClose={handleClose}
                />
            );
        }
        if (currentBox === 'personal') {
            return useNewCreditModalABtest ? (
                <ABTestBoxContainer
                    purchase={purchase}
                    checkedPurchase={checkedPurchase}
                    purchaseItemClick={purchaseItemClick}
                    baseUrl={baseUrl}
                    chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                />
            ) : (
                <BaseBox
                    purchase={purchase}
                    checkedPurchase={checkedPurchase}
                    purchaseItemClick={purchaseItemClick}
                    baseUrl={baseUrl}
                    chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                />
            );
        }
    };

    useEffect(() => {
        // 这里登录按钮已经前置做了混淆登录的判断处理，能正常触发弹窗就一定能用window的数据判断身份
        const userInfo = window.fjuser?.info;
        if (userInfo?.space_type === 'team') {
            dispatch(mainAction.setPlanType('team'));
            setCurrentBox('team');
            if (userInfo?.space_permission === 'owner') {
                dispatch(mainAction.setIsTeamOwner(true));
                dispatch(mainAction.setTeamOwnerTid(userInfo?.current_space_id));
            }
        } else {
            setCurrentBox('personal');
        }
    }, [dispatch]);

    return (
        <div className={Styles.container}>
            <div className={Styles.box}>
                {getBox()}
                <div className={Styles.btn} onClick={handleClose}>
                    <CloseButton />
                </div>
            </div>
        </div>
    );
}
