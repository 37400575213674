import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Styles from './BoxB.module.less';
import InlineSvg from 'react-inlinesvg';
import CorrectIcon from '../../../icon/correct.svg';
import FJUtil from '../../../util/FJUtil';
import FJLocalStore from '../../../store/FJLocalStore';

const jumpToPricingPage = () => {
    FJUtil.ealog('free account ab test 24.12.16 click', {
        'learn more click': 'B-learn',
        'ab window click': 'B',
    });
    const url = FJUtil.getCurrentUrl() + '/pricing.html?boxType=B';
    window.open(url, '_blank');
};

export default function BoxB(props) {
    const {
        purchase,
        checkedPurchase,
        purchaseItemClick,
        baseUrl,
        chooseCreditsAndToPayPanel,
        exchangeRate,
        currentCountryMonetary,
        currentCountryMonetaryISO,
    } = props;

    const entry = useSelector(state => state.mainReducer.entry);
    useEffect(() => {
        const fromAITool = !['editor', 'project', 'billing'].some(keyword => entry.includes(keyword));
        FJUtil.ealog('ai tool page revenue window visit', {
            'free account ab test 24.12.16 visit': 'free credits get window B-ad',
            ...(fromAITool && { 'ai tool name': entry }),
        });
    }, [entry]);

    return (
        <>
            <div className={Styles.left}>
                <p className={Styles.left_title}>{FJLocalStore._('get-ai-credits')}</p>
                <p className={Styles.left_desc}>{FJLocalStore._('enjoy-ai-tools')}</p>
                {purchase.map((item, index) => {
                    const realPrice = FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2);
                    const realPriceStr = FJUtil.formatNumberThousandth(realPrice);
                    const pricePerCredit = (Number(realPrice) / Number(item.base)).toFixed(3);
                    return (
                        <div
                            className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                            key={'list' + index + 1}
                            onClick={() => {
                                purchaseItemClick(index);
                            }}
                        >
                            <div className={Styles.outer_box}>
                                <div className={Styles.item_left}>
                                    <div className={Styles.left_top}>
                                        <img
                                            className={Styles.left_top_icon}
                                            width={26}
                                            height={26}
                                            src={`${baseUrl}Credits-26.webp`}
                                        />
                                        <p className={Styles.left_top_credit}>{item.base}</p>
                                    </div>
                                    <p className={Styles.left_bottom}>
                                        {currentCountryMonetary}
                                        {currentCountryMonetaryISO}
                                        &nbsp;
                                        {FJLocalStore._('dollar-per-credit').replace('xxx', pricePerCredit)}
                                    </p>
                                </div>
                                <div className={Styles.item_right}>
                                    <span>
                                        {currentCountryMonetary}
                                        {currentCountryMonetaryISO}
                                    </span>
                                    <span>{realPriceStr}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <button
                    className={Styles.left_btn}
                    onClick={() => {
                        FJUtil.ealog('free account ab test 24.12.16 click', {
                            'learn more click': 'B-get',
                            'ab window click': 'B',
                        });
                        chooseCreditsAndToPayPanel();
                    }}
                >
                    {FJLocalStore._('choose-now')}
                </button>
                <p className={Styles.tip}>{FJLocalStore._('CreditsValidity')}</p>
            </div>
            <div className={Styles.right}>
                <div className={Styles.content}>
                    <img src={`${baseUrl}new-credit-base.webp`} alt='credit img' width='100%' height='auto' />
                    <div className={Styles.text}>
                        <p>{FJLocalStore._('video-editor-sub')}</p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>{FJLocalStore._('BUSINESS_FEATURE_1')}</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>{FJLocalStore._('up-to-9600')}</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>{FJLocalStore._('BUSINESS_FEATURE_2')}</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>{FJLocalStore._('BUSINESS_FEATURE_12')}</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>{FJLocalStore._('unlimited')}</span>
                        </p>
                        <p>
                            <InlineSvg src={CorrectIcon} />
                            <span>{FJLocalStore._('BUSINESS_FEATURE_11')}</span>
                        </p>
                        <button onClick={jumpToPricingPage}>{FJLocalStore._('learn-more')}</button>
                    </div>
                </div>
            </div>
        </>
    );
}
