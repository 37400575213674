import SubscribeStepContainer from '../SubscribeStep/SubscribeStepContainer';
import Style from './PopupPage.module.less';
import { useSelector } from 'react-redux';

const PopupPage = () => {
    const useNewCreditModal = useSelector(state => state.mainReducer.useNewCreditModal);
    const openCreditsPayPanel = useSelector(state => state.mainReducer.openCreditsPayPanel);
    const step = useSelector(state => state.mainReducer.step);
    const showNewCreditsModal = useNewCreditModal && !openCreditsPayPanel;
    const bgColor = showNewCreditsModal && step === 1 ? 'transparent' : '#ffffff';
    return (
        <div className={Style.popup_box} style={{ backgroundColor: bgColor }}>
            <SubscribeStepContainer popupType />
        </div>
    );
};
export default PopupPage;
