import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import BoxB from './BoxB';
import BoxA from './BoxA';

export default function ABTestBoxContainer(props) {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const shouldRenderA = useMemo(() => {
        // 当前版本AB测试跟着用户id走，奇数为A，偶数为B
        return window.uid % 2 !== 0;
    }, []);

    const _renderBox = () => {
        return shouldRenderA ? (
            <BoxA
                purchase={purchase}
                checkedPurchase={checkedPurchase}
                purchaseItemClick={purchaseItemClick}
                baseUrl={baseUrl}
                chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                exchangeRate={exchangeRate}
                currentCountryMonetary={currentCountryMonetary}
                currentCountryMonetaryISO={currentCountryMonetaryISO}
            />
        ) : (
            <BoxB
                purchase={purchase}
                checkedPurchase={checkedPurchase}
                purchaseItemClick={purchaseItemClick}
                baseUrl={baseUrl}
                chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                exchangeRate={exchangeRate}
                currentCountryMonetary={currentCountryMonetary}
                currentCountryMonetaryISO={currentCountryMonetaryISO}
            />
        );
    };

    return _renderBox();
}
