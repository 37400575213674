/* eslint-disable max-depth */
import SeatsSelector from './SeatsSelector';
import FJLocalStore from '../../store/FJLocalStore';
import InlineSVG from 'react-inlinesvg';
import rightIcon from '../../icon/right.svg';
import questionIcon from '../../icon/question.svg';
import classNames from 'classnames';
import HoverDrop from '../common/HoverDrop';
import FJUtil from '../../util/FJUtil';
import { useDispatch, useSelector } from 'react-redux';
import FJViewConstant from '../../constant/FJViewConstant';
import { AUTO_SUBTITLE_PACKAGE_QUOTA, BACKGROUND_REMOVAL_PACKAGE_QUOTA } from '../../constant/FJValueConstant';
import Style from './PackageItem.module.less';
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react';
import mainAction from '../../reducer/action/mainAction';
import FJAIQuota from './FJAIQuota';

import { useMemo } from 'react';
import FJNetworkStore from '../../store/FJNetworkStore';
import FJMessageStore from '../../store/FJMessageStore';

const PackageItem = (props, ref) => {
    let { packageItemWidth, popupType, itemData, changeStep, isAnnually, index } = props;
    let { name, buttonText } = itemData;

    let currentPlan = useSelector(state => state.mainReducer.currentPlan);
    let discountConf = useSelector(state => state.mainReducer.discountConf);
    let planType = useSelector(state => state.mainReducer.planType);
    let calculatorSeatCount = useSelector(state => state.mainReducer.calculatorSeatCount);
    let teamSubscription = useSelector(state => state.mainReducer.teamSubscription);
    let minSeatCount = useSelector(state => state.mainReducer.minSeatCount);
    let entry = useSelector(state => state.mainReducer.entry);

    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const [seatCount, setSeatCount] = useState(calculatorSeatCount);
    const dispatch = useDispatch();

    const priceBoxRef = useRef(null);

    useImperativeHandle(ref, () => {
        return {
            getSize: () => priceBoxRef.current.getBoundingClientRect(),
        };
    });

    let selectedPeriod = useMemo(() => {
        return isAnnually ? 'annual' : 'monthly';
    }, [isAnnually]);

    const shouldCreateTeam = useCallback(() => {
        return teamSubscription === null;
    }, [teamSubscription]);

    const _getRatePrice = (price, discount) => {
        let rate = discount / 100;
        return Math.floor(price * rate * 100) / 100;
    };

    let curPrice = isAnnually ? itemData.price.annual : itemData.price.monthly;
    let _getPrice = (price, calculateProportionOrNot = false, isTotal = false) => {
        if (window.fjuser) {
            if (!window.fjuser.info.result || window.fjuser.info.policy === 'free') {
                if (discountConf.openDiscount) {
                    let lists = discountConf.lists;
                    if (!isAnnually && calculateProportionOrNot) {
                        return FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2));
                    }
                    if (lists) {
                        let ratePrice;
                        for (const item of lists) {
                            // eslint-disable-next-line max-depth
                            if (!item.package) {
                                ratePrice = _getRatePrice(price, item.discount);
                            }
                            if (item.package && item.package === itemData.package) {
                                if (isAnnually) {
                                    if (calculateProportionOrNot) {
                                        ratePrice = _getRatePrice(price, item.discount);
                                    } else {
                                        if (isTotal) {
                                            ratePrice = _getRatePrice(
                                                _getRatePrice(itemData.price.monthly, item.discount) * 12,
                                                item.discountChannel,
                                            );
                                        } else {
                                            ratePrice = _getRatePrice(
                                                _getRatePrice(itemData.price.monthly, item.discount),
                                                item.discountChannel,
                                            );
                                        }
                                    }
                                }
                            }
                        }
                        return FJUtil.formatNumberThousandth(
                            FJUtil.preciseMultiply([ratePrice, exchangeRate]).toFixed(2),
                        );
                    }
                }
            }
        }

        return FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2));
    };
    let _drawItem = () => {
        let features = popupType ? itemData.shortFeatures : itemData.features;
        if (planType === 'team' && itemData.package === 'free') {
            features = itemData.teamFeatures;
        }
        return features.map(item => {
            let text;
            switch (item.key) {
                case FJViewConstant.AUTO_SUBTITLE: {
                    const quota = AUTO_SUBTITLE_PACKAGE_QUOTA[itemData.package];
                    const yearQuota = quota * 12;
                    text =
                        isAnnually && itemData.package !== 'free'
                            ? FJLocalStore._(item.yearText).replace('xxx', yearQuota)
                            : FJLocalStore._(item.monthText).replace('xxx', quota);
                    break;
                }
                case FJViewConstant.BACKGROUND_REMOVAL: {
                    text = FJLocalStore._(item.text).replace('xxx', BACKGROUND_REMOVAL_PACKAGE_QUOTA[itemData.package]);
                    break;
                }
                case FJViewConstant.AI_QUOTA: {
                    // text = FJLocalStore._(item.text).replace('xxx', FJUtil.packageName(itemData.package));
                    text = isAnnually ? FJLocalStore._(item.text + '-yr') : FJLocalStore._(item.text);
                    break;
                }
                default:
                    text = FJLocalStore._(item.text);
            }

            return (
                <div key={item.text + item.key} className={Style.featureItem}>
                    <div className={Style.icon_box}>
                        <InlineSVG src={rightIcon} />
                    </div>
                    {item.key === FJViewConstant.AI_QUOTA ? (
                        <div className={Style.trigger}>
                            <span className={Style.text_box}>{text}</span>
                            {/* <HoverDrop
                                overlay={
                                    <FJAIQuota
                                        userPackage={itemData.package}
                                        isAnnually={isAnnually}
                                        planType={planType}
                                        seatCount={seatCount}
                                    />
                                }
                            >
                                <div className={Style.question_icon_box}>
                                    <InlineSVG src={questionIcon} />
                                </div>
                            </HoverDrop> */}
                        </div>
                    ) : (
                        <>
                            <span className={Style.text_box}>{text}</span>
                            {item.desc && (
                                <HoverDrop
                                    overlay={<div className={Style.question_drop}>{FJLocalStore._(item.desc)}</div>}
                                >
                                    <div className={Style.question_icon_box}>
                                        <InlineSVG src={questionIcon} />
                                    </div>
                                </HoverDrop>
                            )}
                        </>
                    )}
                </div>
            );
        });
    };
    let _planTotal = () => {
        if (!isAnnually) {
            if (planType === 'team') {
                const seatPrice = itemData.seatPrice?.monthly ?? 9.9;
                let price = itemData.price.monthly + (seatCount - 3) * seatPrice;
                // eslint-disable-next-line no-useless-concat
                return `${currentCountryMonetary}${_getPrice(price)}` + ' ' + FJLocalStore._('PLANS_MONTHLY_TOTAL');
            }
            return '';
        }

        let price;
        if (planType === 'team') {
            const seatPrice = itemData.seatPrice?.annual ?? 19.9;
            price = itemData.price.annual * 12 + (seatCount - 3) * seatPrice * 12;
        } else {
            price = itemData.price.annual * 12;
        }
        // eslint-disable-next-line no-useless-concat
        return `${currentCountryMonetary}${_getPrice(price, false, true)}` + ' ' + FJLocalStore._('PLANS_ANNUAL_TOTAL');
    };
    let _seatPrice = () => {
        if (planType === 'team') {
            const seatPrice = (isAnnually ? itemData.seatPrice?.annual : itemData.seatPrice?.monthly) ?? 19.9;
            return FJLocalStore._('Include-3-seats').replace(
                'xxx',
                `${currentCountryMonetary}${FJUtil.formatNumberThousandth(
                    FJUtil.preciseMultiply([seatPrice, exchangeRate]).toFixed(2),
                )}`,
            );
        }
    };
    let _onClick = useCallback(() => {
        let type = itemData.package;
        let currentDiscountData = [];
        // 设置对应套餐的分区定价
        if (discountConf.openDiscount) {
            let lists = discountConf.lists;
            for (const i in lists) {
                if (lists.hasOwnProperty(i)) {
                    if (lists[i].package === '' || lists[i].package === type) currentDiscountData[i] = lists[i];
                }
            }
            dispatch(mainAction.setCurrentDiscountData(currentDiscountData));
        }
        // changeStep(2, type);
        if (!FJUtil.checkIsLogin()) {
            return;
        } else if (planType === 'team' && shouldCreateTeam()) {
            window.open('/?showCreateTeamModal=true', '_blank');
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        } else {
            // 获取用户信用卡号， 打开支付面板
            FJNetworkStore.getCardInfo()
                .then(response => {
                    // response 数据结构
                    // pp_email:""
                    // ss_customer:"cus_Q48MkSGO7yVKyY"
                    // ss_expired:"4/2029"
                    // ss_last4:"4242"
                    // uid:151279
                    // user_info

                    let subscription =
                        typeof response.data === 'object' ? (Array.isArray(response.data) ? {} : response.data) : null;

                    if (!subscription) {
                        return window.fjmessage && window.fjmessage.addMessage(response.msg || 'request err', 'error');
                    }

                    dispatch(
                        mainAction.setCurrentPlan({
                            last4: subscription.ss_last4,
                        }),
                    );

                    // this.state.selectPlan = this.plan;
                    // this.state.page = 'pay';
                    let packageName = itemData.package;
                    changeStep(2, packageName);

                    // 打开支付页面时，显示的席位数量不能小于最小席位数3 且不能低于已经订阅的团队套餐席位数，因此取最大值
                    let seatNumber = seatCount;
                    if (teamSubscription) {
                        const { members = 0, seats = 0 } = teamSubscription;
                        seatNumber = Math.max(members, seats, seatCount, minSeatCount);
                    }
                    dispatch(mainAction.updateSeatCount(seatNumber));

                    FJUtil.ealog('choose plan select click', {
                        selectButton: packageName + ' ' + selectedPeriod,
                        'open page': document.location.pathname,
                    });
                    FJUtil.ealog('regions pricing AB_pay page visit_2024-12', {
                        plan: packageName + (selectedPeriod === 'annual' ? '_y' : '_m'),
                        AB: window.uid ? (window.uid % 2 === 0 ? 'B' : 'A') : 'undefined',
                    });
                    FJUtil.zgEventLog('套餐选择页-套餐选择点击', {
                        套餐类型: packageName + ' ' + selectedPeriod,
                        页面: !popupType ? 'pricing页面' : '升级弹出面板',
                    });
                    window.scrollTo({
                        top: 0,
                        behavior: 'smooth',
                    });
                })
                .catch(e => {
                    FJMessageStore.addMessage(FJLocalStore._('failed-to-connect-network'), 'error');
                });
        }
    }, [
        changeStep,
        discountConf.lists,
        discountConf.openDiscount,
        dispatch,
        itemData.package,
        minSeatCount,
        planType,
        popupType,
        seatCount,
        selectedPeriod,
        shouldCreateTeam,
        teamSubscription,
    ]);

    const canPlanUpgrade = useMemo(() => {
        let plan = {
            package: itemData.package,
            period: selectedPeriod,
        };
        if (planType === 'team') {
            plan.package = plan.package === 'team' ? 'business' : plan.package;
            if (teamSubscription) {
                return window.checkPlanCanUpgrade(teamSubscription, plan);
            }
            return true;
        }
        return window.checkPlanCanUpgrade(currentPlan, plan);
    }, [itemData.package, selectedPeriod, planType, currentPlan, teamSubscription]);

    // eslint-disable-next-line complexity
    const getButtonDisplayResult = useMemo(() => {
        // 终身版和临时用户都不能购买订阅
        if (currentPlan.period === 'lifetime' && planType !== 'team') {
            // 终身版用户不显示该按钮
            return null;
        }
        // 根据不同的用户类型，展示该套餐上下游套餐可操作状态
        let displayText = '';
        let clickFun = null;

        const jumpToEditorAppFun = () => {
            // 如果是以弹窗的形式打开的该页面，则跳转编辑页行为应该被制止，只允许升级付费行为
            if (entry === 'editor') {
                return;
            }
            FJUtil.goToEditorApp('', true);
        };

        if (planType === 'team') {
            // 未登录的情况下
            if (!FJUtil.isLogin()) {
                switch (itemData.package) {
                    case 'free':
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                        break;
                    case 'team':
                        displayText = FJLocalStore._('BUTTON_GET_TEAM');
                        clickFun = _onClick;
                        break;
                }
                return { displayText, clickFun };
            }
            // 当前套餐类型 1. 用户没有创建团队 2. 创建了团队，就自动享有免费团队套餐，且没有购买付费的团队套餐 3. 付费的团队套餐
            if (teamSubscription === null) {
                switch (itemData.package) {
                    case 'free':
                        displayText = FJLocalStore._('create-team');
                        clickFun = _onClick;
                        break;
                    case 'team':
                        displayText = FJLocalStore._('create-team');
                        clickFun = _onClick;
                        break;
                }
                return { displayText, clickFun };
            }

            if (teamSubscription.package === 'free') {
                // 免费团队套餐用户，免费套餐就直接为跳转编辑页，可以升级的套餐就展示为get team;
                displayText =
                    itemData.package === 'free'
                        ? FJLocalStore._('BUTTON_GET_START')
                        : FJLocalStore._('BUTTON_GET_TEAM');
                clickFun = itemData.package === 'free' ? jumpToEditorAppFun : _onClick;
            } else {
                // 有创建团队的情况，就判断当前套餐能否升级， 能升级就显示upgrade，不能就显示get start
                displayText = canPlanUpgrade ? FJLocalStore._('UPGRADE_NOW') : FJLocalStore._('BUTTON_GET_START');
                clickFun = canPlanUpgrade ? _onClick : jumpToEditorAppFun;
            }
            // 非团队拥有者不能购买团队套餐
            if (window.fjuser?.info?.space_type === 'team' && window.fjuser?.info?.space_permission !== 'owner') {
                clickFun = () => {
                    window.fjmessage?.addMessage(FJLocalStore._('member-cant-buy-plan'), 'warning');
                    return;
                };
            }

            return { displayText, clickFun };
        }

        // 登录情况下
        if (FJUtil.isLogin()) {
            // 当前按钮的展示结果 = 当前用户类型（free、plus、business） + 当前套餐类型 + 当前套餐是否可以升级
            switch (currentPlan.package) {
                case 'free':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = FJLocalStore._('BUTTON_GET_PLUS');
                        clickFun = _onClick;
                    }
                    if (itemData.package === 'business') {
                        displayText = FJLocalStore._('BUTTON_GET_BUSINESS');
                        clickFun = _onClick;
                    }
                    break;
                case 'basic':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = canPlanUpgrade
                            ? FJLocalStore._('BUTTON_GET_PLUS')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade ? _onClick : jumpToEditorAppFun;
                    }
                    if (itemData.package === 'business') {
                        displayText = canPlanUpgrade
                            ? FJLocalStore._('BUTTON_GET_BUSINESS')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade ? _onClick : jumpToEditorAppFun;
                    }
                    break;
                case 'plus':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = canPlanUpgrade
                            ? FJLocalStore._('UPGRADE_NOW')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade ? _onClick : jumpToEditorAppFun;
                    }
                    if (itemData.package === 'business') {
                        displayText = canPlanUpgrade
                            ? FJLocalStore._('BUTTON_GET_BUSINESS')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade ? _onClick : jumpToEditorAppFun;
                    }
                    break;
                case 'business':
                    if (itemData.package === 'free') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'plus') {
                        displayText = FJLocalStore._('BUTTON_GET_START');
                        clickFun = jumpToEditorAppFun;
                    }
                    if (itemData.package === 'business') {
                        displayText = canPlanUpgrade
                            ? FJLocalStore._('UPGRADE_NOW')
                            : FJLocalStore._('BUTTON_GET_START');
                        clickFun = canPlanUpgrade ? _onClick : jumpToEditorAppFun;
                    }
                    break;
            }

            return { displayText, clickFun };
        }

        // 未登录情况下，展示当前套餐的订阅按钮
        return {
            displayText: itemData.package === 'free' ? FJLocalStore._('BUTTON_GET_START') : FJLocalStore._(buttonText),
            clickFun: itemData.package === 'free' ? jumpToEditorAppFun : _onClick,
        };
    }, [
        currentPlan,
        planType,
        itemData.package,
        buttonText,
        _onClick,
        entry,
        isAnnually,
        teamSubscription,
        canPlanUpgrade,
    ]);

    let BUTTON_INFO = getButtonDisplayResult;
    if (BUTTON_INFO) {
        BUTTON_INFO = {
            ...BUTTON_INFO,
            plain:
                itemData.package === 'free' &&
                (BUTTON_INFO.displayText === FJLocalStore._('BUTTON_GET_START') ||
                    BUTTON_INFO.displayText === FJLocalStore._('FREE_BUTTON_SELECT')),
        };
    }

    const seatsData = [
        {
            length: 3,
            title: '1-3',
        },
        {
            length: 5,
            title: '5',
        },
        {
            length: 10,
            title: '10',
        },
        {
            length: 20,
            title: '20',
        },
    ];

    const _onChangeSeats = value => {
        setSeatCount(value);
        dispatch(mainAction.changeTeamSeatCount(value));
    };

    let initialSelectedIndex = seatsData.findIndex(seat => seat.length === calculatorSeatCount);
    let initialValue = '';
    if (initialSelectedIndex === -1) {
        initialValue = `${calculatorSeatCount}`;
    }

    const style = packageItemWidth ? { width: packageItemWidth } : {};

    const isTeamSpace = useMemo(() => {
        return planType === 'team' || window.space_type === 'team';
    }, [planType]);
    const showCurrentIcon = () => {
        const currentPlan = window.fjuser?.info?.current_policy;
        const currentPeriod = window.fjuser?.info?.current_period;
        const selectPeriod = isAnnually ? 'annual' : 'monthly';
        // 终身版用户不显示当前套餐，账号类型和页面类型不对应也不显示
        if (window.fjuser?.info?.lifetime_type || window.fjuser?.info?.space_type !== planType) {
            return false;
        }
        if (isTeamSpace) {
            return (
                (itemData.package === 'free' && currentPlan === 'free') || // 免费时不用区分包年包月
                (itemData.package === 'team' && currentPlan === 'business' && currentPeriod === selectPeriod)
            );
        }
        return (
            (itemData.package === 'free' && currentPlan === 'free') || // 免费时不用区分包年包月
            (itemData.package === currentPlan && currentPeriod === selectPeriod)
        );
    };

    return (
        <div
            className={classNames(Style.listItem, {
                [Style.popup]: popupType,
                [Style.listItem_popular]: itemData.isPopular,
                [Style.team]: planType === 'team',
            })}
            style={style}
        >
            {itemData.isPopular && (
                <div
                    className={classNames(Style.popular, {
                        [Style.team]: itemData.package === 'team',
                    })}
                >
                    {itemData.package === 'team'
                        ? FJLocalStore._('Plan for 2+ people')
                        : FJLocalStore._('PLANS_POPULAR')}
                </div>
            )}
            <span className={Style.plan_name}>{FJLocalStore._(name)} </span>
            {showCurrentIcon() && <span className={Style.current_plan}>{FJLocalStore._('current-plan')}</span>}
            <div className={Style.price_box} ref={priceBoxRef}>
                <span className={Style.monetary}>
                    {currentCountryMonetary}
                    {currentCountryMonetaryISO}
                </span>
                <span className={Style.price}>{_getPrice(curPrice)}</span>
                <div className={Style.unit}>
                    <span
                        className={classNames(
                            Style.cut,
                            itemData.package === 'free' || (!discountConf.openDiscount && !isAnnually)
                                ? Style.cut_hidden
                                : '',
                        )}
                    >
                        {`${currentCountryMonetary}${_getPrice(itemData.price.monthly, true)}`}
                    </span>
                    <span>/{FJLocalStore._('PLANS_MO')}</span>
                </div>
            </div>
            <div className={classNames(Style.count_price, { [Style.count_price_hidden]: itemData.package === 'free' })}>
                <div className={Style.seat_price}>{_seatPrice()}</div>
                <div className={Style.price_total}>{_planTotal()}</div>
            </div>
            {BUTTON_INFO && (
                <div
                    onClick={BUTTON_INFO.clickFun}
                    className={classNames(
                        'CreateTeam',
                        `CreateTeam${index}`,
                        Style.select_btn,
                        {
                            [Style.select_btn_normal]: BUTTON_INFO.plain,
                        },
                        {
                            [Style.select_btn_gray]:
                                BUTTON_INFO.displayText === FJLocalStore._('BUTTON_GET_START') && entry === 'editor',
                        },
                    )}
                >
                    {BUTTON_INFO.displayText}
                </div>
            )}
            {itemData.package === 'team' ? (
                <div className={Style.seats_container}>
                    <div className={Style.seats_title}>{FJLocalStore._('How many seats do you need?')}</div>
                    <SeatsSelector
                        seatsData={seatsData}
                        initialValue={initialValue}
                        initialSelectedIndex={initialSelectedIndex}
                        classname={Style.seats_selector}
                        onChangeSeats={_onChangeSeats}
                    />
                </div>
            ) : null}
            <div className={Style.feature_list}>{_drawItem()}</div>
            {itemData.package !== 'free' && popupType ? (
                <a className={Style.more_btn} href={FJUtil.getPricingUri()} target='_blank'>
                    {FJLocalStore._('See-all-features')}
                </a>
            ) : null}
        </div>
    );
};
export default forwardRef(PackageItem);
