import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';
import Styles from './BoxA.module.less';
import InlineSvg from 'react-inlinesvg';
import TickIcon from '../../../icon/tick.svg';
import ArrowIcon from '../../../icon/arrow-rotate.svg';
import ChangeButton from '../../common/ChangeButton/ChangeButton';
import FJNetworkStore from '../../../store/FJNetworkStore';
import FJUtil from '../../../util/FJUtil';
import FJLocalStore from '../../../store/FJLocalStore';

const jumpToPricingPage = logData => {
    FJUtil.ealog('free account ab test 24.12.16 click', {
        'learn more click': logData,
        'ab window click': 'A',
    });
    const url = FJUtil.getCurrentUrl() + '/pricing.html?boxType=A';
    window.open(url, '_blank');
};

const CreditLeftPart = props => {
    const {
        purchase,
        checkedPurchase,
        purchaseItemClick,
        baseUrl,
        chooseCreditsAndToPayPanel,
        exchangeRate,
        currentCountryMonetary,
        currentCountryMonetaryISO,
    } = props;

    return (
        <div className={Styles.credit_container}>
            {purchase.map((item, index) => {
                const realPrice = FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2);
                const realPriceStr = FJUtil.formatNumberThousandth(realPrice);
                const pricePerCredit = (Number(realPrice) / Number(item.base)).toFixed(3);
                return (
                    <div
                        className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                        key={'list' + index + 1}
                        onClick={() => {
                            purchaseItemClick(index);
                        }}
                    >
                        <div className={Styles.outer_box}>
                            <div className={Styles.item_left}>
                                <div className={Styles.list_left_top}>
                                    <img
                                        className={Styles.left_top_icon}
                                        width={26}
                                        height={26}
                                        src={`${baseUrl}Credits-26.webp`}
                                    />
                                    <p className={Styles.left_top_credit}>{item.base}</p>
                                </div>
                                <p className={Styles.left_bottom}>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                    &nbsp;
                                    {FJLocalStore._('dollar-per-credit').replace('xxx', pricePerCredit)}
                                </p>
                            </div>
                            <div className={Styles.item_right}>
                                <span>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                </span>
                                <span>{realPriceStr}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
            <button className={Styles.left_btn} onClick={chooseCreditsAndToPayPanel}>
                {FJLocalStore._('choose-now')}
            </button>
            <p className={Styles.tip}>{FJLocalStore._('CreditsValidity')}</p>
        </div>
    );
};

const PlanItem = props => {
    const {
        item,
        isAnnually,
        currentItem,
        setCurrentItem,
        exchangeRate,
        currentCountryMonetary,
        currentCountryMonetaryISO,
        discountConf,
    } = props;

    const isPlus = item.package === 'plus';

    const _getRatePrice = (price, discount) => {
        let rate = discount / 100;
        return Math.floor(price * rate * 100) / 100;
    };

    const _getPrice = (price, calculateProportionOrNot = false, isTotal = false) => {
        if (window.fjuser) {
            if (!window.fjuser.info.result || window.fjuser.info.policy === 'free') {
                if (discountConf.openDiscount) {
                    let lists = discountConf.lists;
                    if (!isAnnually && calculateProportionOrNot) {
                        return FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2));
                    }
                    if (lists) {
                        let ratePrice;
                        for (const listItem of lists) {
                            // eslint-disable-next-line max-depth
                            if (!listItem.package) {
                                ratePrice = _getRatePrice(price, listItem.discount);
                            }
                            // eslint-disable-next-line max-depth
                            if (listItem.package && listItem.package === item.package) {
                                // eslint-disable-next-line max-depth
                                if (isAnnually) {
                                    // eslint-disable-next-line max-depth
                                    if (calculateProportionOrNot) {
                                        ratePrice = _getRatePrice(price, listItem.discount);
                                    } else {
                                        // eslint-disable-next-line max-depth
                                        if (isTotal) {
                                            // eslint-disable-next-line max-depth
                                            ratePrice = _getRatePrice(
                                                _getRatePrice(item.price.monthly, listItem.discount) * 12,
                                                listItem.discountChannel,
                                            );
                                        } else {
                                            ratePrice = _getRatePrice(
                                                _getRatePrice(item.price.monthly, listItem.discount),
                                                listItem.discountChannel,
                                            );
                                        }
                                    }
                                }
                            }
                        }
                        return FJUtil.formatNumberThousandth(
                            FJUtil.preciseMultiply([ratePrice, exchangeRate]).toFixed(2),
                        );
                    }
                }
            }
        }

        return FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2));
    };
    const realPrice = isAnnually ? item.price.annual : item.price.monthly;

    return (
        <div
            className={`${Styles.plan_item} ${currentItem === item.package ? Styles.plan_item_active : ''}`}
            onClick={() => {
                if (currentItem === item.package) return;
                setCurrentItem(item.package);
            }}
        >
            <div className={Styles.outer_box}>
                <div className={Styles.price_box}>
                    <div className={Styles.package}>
                        {FJLocalStore._(item.package === 'plus' ? 'PLUS_NAME' : 'BUSINESS_NAME')}
                    </div>
                    <div className={Styles.price}>
                        <span className={Styles.currency}>
                            {currentCountryMonetary}
                            {currentCountryMonetaryISO}
                        </span>
                        <span className={Styles.number}>{_getPrice(realPrice)}</span>
                    </div>
                </div>
                <p className={Styles.text}>
                    <InlineSvg src={TickIcon} />
                    <span>{isPlus ? FJLocalStore._('PLUS_FEATURE_1') : FJLocalStore._('BUSINESS_FEATURE_1')}</span>
                </p>
                <p className={Styles.text}>
                    <InlineSvg src={TickIcon} />
                    <span>
                        {isAnnually ? FJLocalStore._(`${item.package}-yr`) : FJLocalStore._(`${item.package}-mo`)}
                    </span>
                </p>
                <p
                    className={Styles.learn_more}
                    onClick={() => {
                        jumpToPricingPage(`A-${item.package}-learn`);
                    }}
                >
                    {FJLocalStore._('learn-more')}
                </p>
            </div>
        </div>
    );
};

const SubscribeLeftPart = props => {
    const { exchangeRate, currentCountryMonetary, currentCountryMonetaryISO } = props;
    const planData = useSelector(state => state.mainReducer.planData);
    const isAnnually = useSelector(state => state.mainReducer.isAnnually);
    const planType = useSelector(state => state.mainReducer.planType);
    const discountConf = useSelector(state => state.mainReducer.discountConf);
    const displayPlanData = planData.filter(plan => plan.package !== 'team' && plan.package !== 'free');
    const [currentItem, setCurrentItem] = useState('plus');
    const dispatch = useDispatch();

    const changePlan = value => {
        window.isAnnually = value;
        dispatch(mainAction.updateIsAnnually(value));
    };

    const changeStep = (stepNum, page) => {
        if (page) {
            window.selectPage = page;
            dispatch(mainAction.changeSelectPageAction(page));
        }
        dispatch(mainAction.changeStepAction(stepNum));
    };

    const choosePlanToPayPanel = () => {
        FJUtil.ealog('free account ab test 24.12.16 click', {
            'learn more click': 'A-get',
            'ab window click': 'A',
        });
        FJNetworkStore.getCardInfo().then(response => {
            let subscription =
                typeof response.data === 'object' ? (Array.isArray(response.data) ? {} : response.data) : null;

            if (!subscription) {
                return window.fjmessage && window.fjmessage.addMessage(response.msg || 'request err', 'error');
            }

            dispatch(
                mainAction.setCurrentPlan({
                    last4: subscription.ss_last4,
                }),
            );
            changeStep(2, currentItem);
        });
    };

    const getDiscount = () => {
        let discount = planType === 'team' ? '33%' : '40%';
        if (discountConf.openDiscount) {
            let lists = discountConf.lists;
            if (lists) {
                let minDiscountChannel;
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].discountChannel && lists[i].discountChannel !== 0) {
                        if (minDiscountChannel === undefined || lists[i].discountChannel < minDiscountChannel) {
                            minDiscountChannel = 100 - lists[i].discountChannel;
                        }
                    }
                }
                discount = minDiscountChannel + '%';
            }
        }
        return discount;
    };

    let discount = getDiscount();

    return (
        <div className={Styles.sub_container}>
            <div className={Styles.time_changer}>
                <span className={Styles.month}>{FJLocalStore._('PAY_BILLED_MONTHLY')}</span>
                <ChangeButton onClick={changePlan} value={isAnnually} />
                <div className={Styles.year}>{FJLocalStore._('PAY_BILLED_ANNUALLY')}</div>
                <span className={Styles.save_up}>
                    {FJLocalStore._('SAVE_UP_TO').replace('xxx', discount)}
                    <InlineSvg src={ArrowIcon} />
                </span>
            </div>
            <div className={Styles.content}>
                {displayPlanData.map((item, index) => {
                    return (
                        <Fragment key={'plan item' + index + 1}>
                            <PlanItem
                                item={item}
                                isAnnually={isAnnually}
                                currentItem={currentItem}
                                setCurrentItem={setCurrentItem}
                                exchangeRate={exchangeRate}
                                discountConf={discountConf}
                                currentCountryMonetary={currentCountryMonetary}
                                currentCountryMonetaryISO={currentCountryMonetaryISO}
                            />
                        </Fragment>
                    );
                })}
                <button onClick={choosePlanToPayPanel}>{FJLocalStore._('choose-now')}</button>
            </div>
        </div>
    );
};

const SubscribeRightPart = props => {
    const { baseUrl } = props;

    return (
        <>
            <img src={`${baseUrl}new-credit-subscibe.webp`} alt='sub img' className={Styles.sub_img} />
            <div className={Styles.sub_content}>
                <p>{FJLocalStore._('sub-plan')}</p>
                <button
                    onClick={() => {
                        jumpToPricingPage('A-ad-learn');
                    }}
                >
                    {FJLocalStore._('learn-more')}
                </button>
            </div>
        </>
    );
};

export default function ABTestBoxB(props) {
    const {
        purchase,
        checkedPurchase,
        purchaseItemClick,
        baseUrl,
        chooseCreditsAndToPayPanel,
        exchangeRate,
        currentCountryMonetary,
        currentCountryMonetaryISO,
    } = props;
    const [currentPanel, setCurrentPanel] = useState('credit');
    const entry = useSelector(state => state.mainReducer.entry);

    useEffect(() => {
        const fromAITool = !['editor', 'project', 'billing'].some(keyword => entry.includes(keyword));
        FJUtil.ealog('ai tool page revenue window visit', {
            'free account ab test 24.12.16 visit': 'free credits get window A-switch',
            ...(fromAITool && { 'ai tool name': entry }),
        });
    }, [entry]);

    return (
        <>
            <div className={Styles.left}>
                <div className={Styles.left_top}>
                    <img
                        src='https://resource.flexclip.com//pages/company/pricing/banner.png'
                        alt='btn background'
                        width={310}
                        height={48}
                    />
                    <button
                        className={`${Styles.buttonItem} 
                            ${currentPanel === 'credit' ? Styles.buttonItemActive : ''}`}
                        onClick={() => {
                            setCurrentPanel('credit');
                        }}
                    >
                        {FJLocalStore._('Credits')}
                    </button>
                    <button
                        className={`${Styles.buttonItem} 
                            ${currentPanel !== 'credit' ? Styles.buttonItemActive : ''}`}
                        onClick={() => {
                            setCurrentPanel('subscribe');
                        }}
                    >
                        {FJLocalStore._('Subscription')}
                    </button>
                </div>
                <div className={Styles.left_content}>
                    {currentPanel === 'credit' ? (
                        <CreditLeftPart
                            baseUrl={baseUrl}
                            purchase={purchase}
                            checkedPurchase={checkedPurchase}
                            purchaseItemClick={purchaseItemClick}
                            chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                            exchangeRate={exchangeRate}
                            currentCountryMonetary={currentCountryMonetary}
                            currentCountryMonetaryISO={currentCountryMonetaryISO}
                        />
                    ) : (
                        <SubscribeLeftPart
                            exchangeRate={exchangeRate}
                            currentCountryMonetary={currentCountryMonetary}
                            currentCountryMonetaryISO={currentCountryMonetaryISO}
                        />
                    )}
                </div>
            </div>
            <div className={Styles.right}>
                {currentPanel === 'credit' ? (
                    <img src={`${baseUrl}new-credit-credit.webp`} alt='credit img' className={Styles.credit_img} />
                ) : (
                    <SubscribeRightPart baseUrl={baseUrl} />
                )}
            </div>
        </>
    );
}
