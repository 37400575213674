import React from 'react';
import { useSelector } from 'react-redux';
import Styles from './TeamBox.module.less';
import FJLocalStore from '../../../store/FJLocalStore';
import FJUtil from '../../../util/FJUtil';
import InlineSvg from 'react-inlinesvg';
import IconOne from '../../../icon/box-1.svg';
import IconTwo from '../../../icon/box-2.svg';
import IconThree from '../../../icon/box-3.svg';

const TeamOwnerLeft = props => {
    const {
        purchase = [],
        checkedPurchase,
        purchaseItemClick,
        baseUrl,
        chooseCreditsAndToPayPanel,
        exchangeRate,
        currentCountryMonetary,
        currentCountryMonetaryISO,
    } = props;
    return (
        <div className={Styles.ownerLeft}>
            <p className={Styles.left_title}>{FJLocalStore._('get-team-ai-credits')}</p>
            <p className={Styles.left_desc}>{FJLocalStore._('get-team-ai-credits-desc')}</p>
            {purchase.map((item, index) => {
                const realPrice = FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2);
                const realPriceStr = FJUtil.formatNumberThousandth(realPrice);
                const pricePerCredit = (Number(realPrice) / Number(item.base)).toFixed(3);
                return (
                    <div
                        className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                        key={'list' + index + 1}
                        onClick={() => {
                            purchaseItemClick(index);
                        }}
                    >
                        <div className={Styles.outer_box}>
                            <div className={Styles.item_left}>
                                <div className={Styles.left_top}>
                                    <img
                                        className={Styles.left_top_icon}
                                        width={26}
                                        height={26}
                                        src={`${baseUrl}Credits-26.webp`}
                                    />
                                    <p className={Styles.left_top_credit}>{item.base}</p>
                                </div>
                                <p className={Styles.left_bottom}>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                    &nbsp;
                                    {FJLocalStore._('dollar-per-credit').replace('xxx', pricePerCredit)}
                                </p>
                            </div>
                            <div className={Styles.item_right}>
                                <span>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                </span>
                                <span>{realPriceStr}</span>
                            </div>
                        </div>
                    </div>
                );
            })}
            <button className={Styles.left_btn} onClick={chooseCreditsAndToPayPanel}>
                {FJLocalStore._('choose-now')}
            </button>
            <p className={Styles.tip}>{FJLocalStore._('CreditsValidity')}</p>
        </div>
    );
};

const TeamMemberLeft = props => {
    const { handleClose } = props;
    return (
        <div className={Styles.memberLeft}>
            <div className={Styles.innerBox}>
                <p className={Styles.left_title}>{FJLocalStore._('tcr1')}</p>
                <p className={Styles.left_desc}>{FJLocalStore._('ai-page-teammeb')}</p>
                <div className={Styles.descBox}>
                    <InlineSvg src={IconOne} />
                    <p className={Styles.tip} dangerouslySetInnerHTML={{ __html: FJLocalStore._('dd1') }} />
                </div>
                <div className={Styles.descBox}>
                    <InlineSvg src={IconTwo} />
                    <p className={Styles.tip} dangerouslySetInnerHTML={{ __html: FJLocalStore._('dd2') }} />
                </div>
                <div className={Styles.descBox}>
                    <InlineSvg src={IconThree} />
                    <p className={Styles.tip} dangerouslySetInnerHTML={{ __html: FJLocalStore._('dd3') }} />
                </div>
                <button className={Styles.left_btn} onClick={handleClose}>
                    {FJLocalStore._('ai-page-teammeb-got')}
                </button>
            </div>
        </div>
    );
};

export default function TeamBox(props) {
    const {
        purchase = [],
        checkedPurchase,
        purchaseItemClick,
        baseUrl,
        chooseCreditsAndToPayPanel,
        isTeamOwner,
        handleClose,
    } = props;
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    
    return (
        <>
            {isTeamOwner ? (
                <TeamOwnerLeft
                    purchase={purchase}
                    checkedPurchase={checkedPurchase}
                    purchaseItemClick={purchaseItemClick}
                    baseUrl={baseUrl}
                    chooseCreditsAndToPayPanel={chooseCreditsAndToPayPanel}
                    exchangeRate={exchangeRate}
                    currentCountryMonetary={currentCountryMonetary}
                    currentCountryMonetaryISO={currentCountryMonetaryISO}
                />
            ) : (
                <TeamMemberLeft handleClose={handleClose} />
            )}
            <div className={Styles.right}>
                <img src={`${baseUrl}new-credit-credit.webp`} alt='credit img' width={460} height={620} />
            </div>
        </>
    );
}
