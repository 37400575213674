import Style from './PaypalBtn.module.less';
import { useEffect, useRef, useState } from 'react';
import FJLocalStore from '../../store/FJLocalStore';
import mainAction from '../../reducer/action/mainAction';
import FJMessageStore from '../../store/FJMessageStore';
import { useDispatch, useSelector } from 'react-redux';
import FJNetworkStore from '../../store/FJNetworkStore';
import store from '../../reducer/reducer/FJRoot';
import FJUtil from '../../util/FJUtil';
import { useINOneTimePayment } from '../../hooks/useINOneTimePayment';
import paypalBtnBase64 from '../../assets/base64/paypay-btn-base64';

let PaypalBtn = props => {
    let { style, getPlan, isUpgrade, paypalOnClick } = props;
    let dispatch = useDispatch();
    let { selectPage, currentPlan, dataInfo, currentCountryMonetaryISO } = useSelector(state => {
        return {
            selectPage: state.mainReducer.selectPage,
            currentPlan: state.mainReducer.currentPlan,
            dataInfo: state.mainReducer.dataInfo,
            currentCountryMonetaryISO: state.mainReducer.currentCountryMonetaryISO,
        };
    });

    let buttons = useRef(null);
    let hasRendered = useRef(false);
    let cityCodeRef = useRef('cn');
    let [hasPop, setHasPop] = useState(false);

    const useNewCreditModal = useSelector(state => state.mainReducer.useNewCreditModal);
    const useNewCreditModalABtest = useSelector(state => state.mainReducer.useNewCreditModalABtest);
    const subThroughNewModal = useNewCreditModal && useNewCreditModalABtest;

    useEffect(() => {
        _getCityCode();
        return () => {
            if (buttons.current && buttons.current.close && hasRendered.current) {
                buttons.current.close();
                hasRendered.current = false;
                buttons.current = null;
            }
        };
    }, []);
    let _getCityCode = () => {
        try {
            FJNetworkStore.getUserCountryCode().then(countryCode => {
                cityCodeRef.current = countryCode;
            });
        } catch (e) {}
    };
    let _getPlan = () => {
        return getPlan();
    };
    let getCookie = name => {
        let arr;
        let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if (arr === document.cookie.match(reg)) return unescape(arr[2]);
        else return null;
    };
    let refreshInvoices = () => {
        // 刷新账单列表
        if (window.fj.billing && window.fj.billing.chargeHistory) {
            window.FJGlobalariable.modSubscription.network
                .updateInvoices()
                .then(res => {
                    if (res.data.length === window.fj.billing.chargeHistory.length) {
                        setTimeout(() => {
                            refreshInvoices();
                        }, 3000);
                    } else {
                        window.fj.billing.chargeHistory = res.data;
                    }
                })
                .catch(error => {})
                .finally(() => {});
        }
    };

    const boxTypeDescribe = sessionStorage.getItem('6.2.0boxTypeDesc');

    window.getSubscribeId = (subscriptionID, boolean, code) => {
        let total = 0;
        let beginTime = Date.now();
        const plan = _getPlan();
        let _checkSubscription = () => {
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .checkoutPaypalSubscriptionStatus(subscriptionID, plan, isUpgrade())
                .then(res => {
                    FJUtil.checkLogin(res.code);
                    if (res.code === 214 || res.code === 335) {
                        return;
                    }
                    let sub_status = res.data.sub_status;
                    if (sub_status instanceof Object) {
                        FJUtil.notifyToUpdateCredit();
                        window.FJGlobalariable.modSubscription.updateSubscriptionInfo(sub_status);
                        let lastCurrentPlan;
                        if (plan.teamId) {
                            lastCurrentPlan = { ...store.getState().mainReducer.teamSubscription };
                            dispatch(mainAction.setTeamSubscription(sub_status));
                        } else {
                            lastCurrentPlan = { ...store.getState().mainReducer.currentPlan };
                            dispatch(
                                mainAction.setCurrentPlan({
                                    package: sub_status.package,
                                    period: sub_status.period,
                                    last4: sub_status.last4,
                                    price: sub_status.price,
                                    period_end: sub_status.period_end,
                                    status: sub_status.status,
                                    sub_type: sub_status.sub_type,
                                }),
                            );
                        }
                        dispatch(
                            mainAction.setDataInfo({
                                amount: sub_status.amount,
                                coupon: dataInfo.appliedCoupon,
                                transaction_id: sub_status.transaction_id,
                            }),
                        );
                        window.FJGlobalariable.modSubscription.state.amount = sub_status.amount;
                        window.FJGlobalariable.modSubscription.state.coupon = dataInfo.appliedCoupon;
                        window.FJGlobalariable.modSubscription.state.transaction_id = sub_status.transaction_id;
                        window.FJGlobalariable.modSubscription.state.page = 'successful';
                        dispatch(mainAction.changeLoadingStatusAction(false));
                        dispatch(mainAction.changeStepAction(3));
                        // this.refreshInvoices();
                        refreshInvoices();

                        const state = store.getState().mainReducer;
                        const currentPlan = selectPage === 'team' ? state.teamSubscription : state.currentPlan;
                        let pay_type = currentPlan.package + '_' + currentPlan.period;
                        let text = 'Create';
                        if ('free' !== lastCurrentPlan.package) {
                            pay_type = lastCurrentPlan.package + '_' + lastCurrentPlan.period + '-' + pay_type;
                            text = 'Upgrade';
                        }

                        // 统计事件
                        if (window.ealog && window.ealog.setUserProperties) {
                            let showSimpleTimeline = getCookie(window.fjuser.info.id + '_close_simple_timeline');
                            let editor_mode = window.fjuser.info.is_simple_timeline
                                ? 'simple timeline'
                                : showSimpleTimeline
                                ? 'storyboard'
                                : '';
                            window.ealog.setUserProperties({ 'editor mode': editor_mode });
                            if (selectPage === 'team') {
                                window.ealog.setUserProperties({
                                    'team type': currentPlan.package + ' ' + currentPlan.period,
                                });
                            } else {
                                window.ealog.setUserProperties({
                                    'account type': currentPlan.package + ' ' + currentPlan.period,
                                });
                            }
                        }

                        window.ealog &&
                            window.ealog.addEvent('revenue', {
                                price: sub_status.amount / 100,
                                pay_type: pay_type,
                                'is coupon code': !!dataInfo.appliedCoupon,
                                'coupon code off':
                                    dataInfo.appliedCoupon &&
                                    (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                                'coupon code': dataInfo.appliedCoupon,
                                'payment platform': 'paypal',
                                ...(subThroughNewModal && {
                                    'free account ab test 24.12.16': 'free credits get window A-switch',
                                }),
                                ...(boxTypeDescribe && { 'free account ab test 24.12.16': boxTypeDescribe }),
                            });
                        window.gaEvent('subscription_upgrade', window.FJGlobalariable.modSubscription.entry, '');
                        window.gaPage('subscription/' + pay_type + '.html', 'Subscription ' + text);
                    } else if (sub_status === 'failed') {
                        // window.FJGlobalariable.modSubscription.showLoading = false;
                        dispatch(mainAction.changeLoadingStatusAction(false));
                        if (currentPlan.package === 'free') {
                            // window.fjmessage.addMessage(text_('SUB.PLEASE_USE_STRIPE'), 'error');
                            FJMessageStore.addMessage(FJLocalStore._('PLEASE_USE_STRIPE'), 'error');
                        }
                    } else {
                        _checkSubscription();
                    }
                })
                .catch(err => {
                    let tryAgain = true;
                    if (err.type === 'ajax') {
                        total += 1;
                        if (total > 30 && Date.now() > beginTime + 30 * 1000) {
                            tryAgain = false;
                        }
                    }
                    if (tryAgain) {
                        _checkSubscription(500);
                    } else {
                        FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                        // window.FJGlobalariable.modSubscription.showLoading = false;
                        dispatch(mainAction.changeLoadingStatusAction(false));
                    }
                });
        };
        if (boolean) {
            _checkSubscription();
        } else {
            FJUtil.checkLogin(code);
        }
    };

    window.getOneTimePaymentId = (paymentId, boolean, code) => {
        let total = 0;
        let beginTime = Date.now();
        const plan = _getPlan();
        let _checkSubscription = () => {
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .checkoutINPaypalSubscriptionStatus(paymentId)
                .then(res => {
                    FJUtil.checkLogin(res.code);
                    if (res.code === 214 || res.code === 335) {
                        return;
                    }
                    let sub_status = res.data.sub_status;
                    if (sub_status instanceof Object) {
                        FJUtil.notifyToUpdateCredit();
                        window.FJGlobalariable.modSubscription.updateSubscriptionInfo(sub_status);
                        let lastCurrentPlan;
                        if (plan.teamId) {
                            lastCurrentPlan = { ...store.getState().mainReducer.teamSubscription };
                            dispatch(mainAction.setTeamSubscription(sub_status));
                        } else {
                            lastCurrentPlan = { ...store.getState().mainReducer.currentPlan };
                            dispatch(
                                mainAction.setCurrentPlan({
                                    package: sub_status.package,
                                    period: sub_status.period,
                                    last4: sub_status.last4,
                                    price: sub_status.price,
                                    period_end: sub_status.period_end,
                                    status: sub_status.status,
                                    sub_type: sub_status.sub_type,
                                }),
                            );
                        }
                        dispatch(
                            mainAction.setDataInfo({
                                amount: sub_status.amount,
                                coupon: dataInfo.appliedCoupon,
                                transaction_id: sub_status.transaction_id,
                            }),
                        );
                        window.FJGlobalariable.modSubscription.state.amount = sub_status.amount;
                        window.FJGlobalariable.modSubscription.state.coupon = dataInfo.appliedCoupon;
                        window.FJGlobalariable.modSubscription.state.transaction_id = sub_status.transaction_id;
                        window.FJGlobalariable.modSubscription.state.page = 'successful';
                        dispatch(mainAction.changeLoadingStatusAction(false));
                        dispatch(mainAction.changeStepAction(3));
                        // this.refreshInvoices();
                        refreshInvoices();

                        const state = store.getState().mainReducer;
                        const currentPlan = selectPage === 'team' ? state.teamSubscription : state.currentPlan;
                        let pay_type = currentPlan.package + '_' + currentPlan.period;
                        let text = 'Create';
                        if ('free' !== lastCurrentPlan.package) {
                            pay_type = lastCurrentPlan.package + '_' + lastCurrentPlan.period + '-' + pay_type;
                            text = 'Upgrade';
                        }

                        // 统计事件
                        if (window.ealog && window.ealog.setUserProperties) {
                            let showSimpleTimeline = getCookie(window.fjuser.info.id + '_close_simple_timeline');
                            let editor_mode = window.fjuser.info.is_simple_timeline
                                ? 'simple timeline'
                                : showSimpleTimeline
                                ? 'storyboard'
                                : '';
                            window.ealog.setUserProperties({ 'editor mode': editor_mode });
                            if (selectPage === 'team') {
                                window.ealog.setUserProperties({
                                    'team type': currentPlan.package + ' ' + currentPlan.period,
                                });
                            } else {
                                window.ealog.setUserProperties({
                                    'account type': currentPlan.package + ' ' + currentPlan.period,
                                });
                            }
                        }

                        window.ealog &&
                            window.ealog.addEvent('revenue', {
                                price: sub_status.amount / 100,
                                pay_type: pay_type,
                                'is coupon code': !!dataInfo.appliedCoupon,
                                'coupon code off':
                                    dataInfo.appliedCoupon &&
                                    (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                                'coupon code': dataInfo.appliedCoupon,
                                'payment platform': 'paypal',
                                ...(subThroughNewModal && {
                                    'free account ab test 24.12.16': 'free credits get window A-switch',
                                }),
                                ...(boxTypeDescribe && { 'free account ab test 24.12.16': boxTypeDescribe }),
                            });
                        window.gaEvent('subscription_upgrade', window.FJGlobalariable.modSubscription.entry, '');
                        window.gaPage('subscription/' + pay_type + '.html', 'Subscription ' + text);
                    } else if (sub_status === 'failed') {
                        // window.FJGlobalariable.modSubscription.showLoading = false;
                        dispatch(mainAction.changeLoadingStatusAction(false));
                        if (currentPlan.package === 'free') {
                            // window.fjmessage.addMessage(text_('SUB.PLEASE_USE_STRIPE'), 'error');
                            FJMessageStore.addMessage(FJLocalStore._('PLEASE_USE_STRIPE'), 'error');
                        }
                    } else {
                        _checkSubscription();
                    }
                })
                .catch(err => {
                    let tryAgain = true;
                    if (err.type === 'ajax') {
                        total += 1;
                        if (total > 30 && Date.now() > beginTime + 30 * 1000) {
                            tryAgain = false;
                        }
                    }
                    if (tryAgain) {
                        _checkSubscription(500);
                    } else {
                        FJMessageStore.addMessage(FJLocalStore._('CONNECT_NETWORK_FAILED'), 'error');
                        // window.FJGlobalariable.modSubscription.showLoading = false;
                        dispatch(mainAction.changeLoadingStatusAction(false));
                    }
                });
        };
        if (boolean) {
            _checkSubscription();
        } else {
            FJUtil.checkLogin(code);
        }
    };

    const isInOneTimepayment = useINOneTimePayment();

    let paypalSubmit = () => {
        paypalOnClick && paypalOnClick();

        if (hasPop) {
            return;
        }
        setHasPop(true);

        const target = window.open(
            '',
            '_blank',
            'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
        );

        if (target) {
            FJUtil.ealog('subscribe browser permissions', { state: 'disable', payType: 'paypal' });
        } else {
            FJUtil.ealog('subscribe browser permissions', { state: 'available', payType: 'paypal' });
        }

        const errorFn = e => {
            if (e?.message === '429') {
                window.fjmessage && window.fjmessage.addMessage(FJLocalStore._('FAIL_WITH_TOO_MANY_REQUESTS'), 'error');
            } else {
                window.fjmessage && window.fjmessage.addMessage(FJLocalStore._('PLEASE_USE_PAYPAL'), 'error');
            }
        };

        const openWidowFn = async approvalUrl => {
            const frame =
                target ||
                window.open(
                    approvalUrl,
                    '_blank',
                    'height=600,width=600,status=yes,top=200,left=400,toolbar=no,menubar=no,location=no',
                );

            if (target) {
                target.location.href = approvalUrl;
            }

            frame.opener = window;
            return new Promise((resolve, reject) => {
                const timer = setInterval(() => {
                    try {
                        if (frame.closed) {
                            clearInterval(timer);
                            resolve();
                        }
                    } catch (e) {
                        clearInterval(timer);
                        reject(e);
                    }
                }, 100);
            });
        };

        const { plan, teamId, seats } = _getPlan();
        const user_info = {
            browser: window.fjuser ? window.fjuser.getBrowser() : '',
            geo: cityCodeRef.current,
            device: window.fjuser ? window.fjuser.getDeviceInfo() : '',
        };

        // 印度地区单独采用一套接口，使用接口返回的url打开支付窗口
        if (isInOneTimepayment) {
            const { plan } = _getPlan();
            window.FJGlobalariable.modSubscription.network
                .getINPayPalWindowUrl(plan, dataInfo.appliedCoupon, {
                    team_id: teamId,
                    seats,
                    user_info: JSON.stringify(user_info),
                })
                .then(response => {
                    const { code, data, msg } = response;

                    if (target.closed) {
                        throw new Error('window closed');
                    }

                    if (code === 200) {
                        if (msg === 'free_of_charge') {
                            // 如果是 100% off 的情况，由前端直接处理
                            const { paymentId, is_success, code } = data;
                            window.getOneTimePaymentId(paymentId, is_success, code);
                            target?.close && target.close();
                            return;
                        }
                        if (!data?.approvalUrl) {
                            target?.close && target.close();
                            throw new Error('no approvalUrl');
                        }
                        return openWidowFn(data.approvalUrl);
                    } else {
                        target?.close && target.close();
                        throw new Error(code);
                    }
                })
                .catch(e => errorFn(e))
                .finally(() => setHasPop(false));
            return;
        }

        window.FJGlobalariable.modSubscription.network
            .getPayPalWindowUrl(plan, dataInfo.appliedCoupon, {
                teamId,
                seats,
                user_info: JSON.stringify(user_info),
                upgrade: isUpgrade(),
                currency: currentCountryMonetaryISO,
            })
            .then(response => {
                if (target.closed) {
                    throw new Error('window closed');
                }
                const { code, data } = response;
                if (code === 200 && data?.approvalUrl) {
                    return openWidowFn(data.approvalUrl);
                } else {
                    target?.close && target.close();
                    throw new Error(code);
                }
            })
            .catch(() => errorFn())
            .finally(() => setHasPop(false));
    };
    return (
        <>
            <div className={Style.paypal_box_new} style={style} onClick={paypalSubmit}>
                <img width={63} height={20} src={paypalBtnBase64} alt='' />
            </div>
            {hasPop && (
                <div className={Style.mask_box}>
                    <div className={Style.mask} />
                </div>
            )}
        </>
    );
};
export default PaypalBtn;
