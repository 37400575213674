import Style from './PayPanelCredits.module.less';
import InlineSVG from 'react-inlinesvg';
import shieldIcon from '../../icon/secure.svg';
import rightIcon from '../../icon/right.svg';

import FJLocalStore from '../../store/FJLocalStore';
import PayMethod from '../../PayMethod/PayMethod';
import CreditCardPanel from '../CreditCardPanel/CreditCardPanel';
import HeadBrick from '../HeadBrick/HeadBrick';
import classNames from 'classnames';
import CardInfoBrick from '../CardInfoBrick/CardInfoBrick';
import PaypalBtn from '../PayPalBtnCredits/PaypalBtn';
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// import CreditsSvg from '../../icon/Credits.svg';
import addSvg from '../../icon/add.svg';
import freeGiftSvg from '../../icon/free-gift.svg';

import InlineSvg from 'react-inlinesvg';
import classnames from 'classnames';
import mainAction from '../../reducer/action/mainAction';
import CouponBrick from '../CreditsPanel/CreditsConpon/CouponBrick';
import FJUtil from '../../util/FJUtil';
import FJNetworkStore from '../../store/FJNetworkStore';
import store from '../../reducer/reducer/FJRoot';
import FJMessageStore from '../../store/FJMessageStore';
import FJViewConstant from '../../constant/FJViewConstant';
import BANNERBase64 from '../../assets/base64/banner-base64';
import FJStripePayAction from '../../reducer/action/FJStripePayAction';

const PayPanel = props => {
    const { popupType, purchase = [] } = props;
    const dispatch = useDispatch();
    // const payMethod = useSelector(state => state.mainReducer.payMethod);
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const currentPlan = useSelector(state => state.mainReducer.currentPlan);
    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const { info: currentCheckedCreditPackage } = useSelector(state => state.mainReducer.creditsPayData);

    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const loadStripeUIStatus = useSelector(state => state.mainReducer.loadStripeUIStatus);
    const entry = useSelector(state => state.mainReducer.entry);

    const useNewCreditModal = useSelector(state => state.mainReducer.useNewCreditModal);
    const isTeamOwner = useSelector(state => state.mainReducer.isTeamOwner);
    const teamOwnerTid = useSelector(state => state.mainReducer.teamOwnerTid);
    const planType = useSelector(state => state.mainReducer.planType);

    let formRef = useRef(null);
    const couponBrickRef = useRef(null);

    const [response, setResponse] = useState('');

    // payMethod: 'stripe', // 支付方式 stripe | paypal
    const [payMethod, setPayMethod] = useState('stripe');

    // 将选择的积分套餐进行渲染展示
    const __drawCredits = () => {
        return (
            Array.isArray(purchase) &&
            purchase
                .filter((item, index) => index === creditsPayData.index)
                .map((item, index) => {
                    return (
                        <div key={index} className={classnames(Style.purchaseItem)}>
                            <div className={Style.purchaseItemIconBox}>
                                {/* <InlineSvg src={CreditsSvg} /> */}
                                <img
                                    src='https://resource.flexclip.com/app/subscription/img/Credits-26.webp'
                                    alt='coin'
                                    width={26}
                                    height={26}
                                />
                            </div>
                            {item.base}&nbsp;
                            {FJLocalStore._(item.baseUnit)}
                            {item.present ? (
                                <>
                                    <div className={classnames(Style.purchaseItemIconBox, Style.addIcon)}>
                                        <InlineSvg src={addSvg} />
                                    </div>
                                    <div className={Style.purchaseItemIconBox}>
                                        <InlineSvg src={freeGiftSvg} />
                                    </div>
                                    {item.present}&nbsp;
                                    {FJLocalStore._(item.presentUnit)}
                                </>
                            ) : (
                                ''
                            )}
                        </div>
                    );
                })
        );
    };

    // 展示结算价格
    let countPrice = () => {
        let obj;
        if (Array.isArray(purchase)) {
            obj = purchase.find((item, index) => index === creditsPayData.index);
        }

        return obj ? (
            <>
                {' '}
                <span className={Style.currentCountryMonetary}>
                    {currentCountryMonetary || obj.priceUnit}
                    {currentCountryMonetaryISO || ''}
                </span>
                <span className={Style.price}>
                    {FJUtil.formatNumberThousandth(
                        FJUtil.preciseMultiply([parseFloat(obj.price), exchangeRate]).toFixed(2),
                    )}
                </span>
            </>
        ) : (
            <span className={Style.price}>$ 0</span>
        );
    };

    // 展示feature
    const __drawFeatureItem = () => {
        return new Array(10).fill(null).map((item, index) => {
            return (
                <div key={index} className={Style.featureItem}>
                    <div className={Style.icon_box}>
                        <InlineSVG src={rightIcon} />
                    </div>
                    <span className={Style.text_box}>{FJLocalStore._(`rules-line-${index + 1}-1`)}</span>
                </div>
            );
        });
    };

    let faq = () => {
        let text = FJLocalStore._('PAY_FAQS');
        if (window.FJGlobalariable.NoJumpFAQPages) {
            text = text.replace('%s', '/contact.html');
            text = text.replace('%s', 'u-link-gray');
        } else {
            text = text.replace('%s', '/faq.html');
            text = text.replace('%s', 'u-link-gray');
            text = text.replace('%s', '/contact.html');
            text = text.replace('%s', 'u-link-gray');
        }
        return text;
    };

    // 切换支付方式
    let _changePayMethod = method => {
        setPayMethod(method);
        setResponse('');
        // dispatch(mainAction.changePayMethodAction(method));
    };

    let showChardInfo = () => {
        if (FJUtil.isNewPay()) {
            return false;
        }
        return currentPlan.last4;
    };

    let showChardForm = () => {
        let flag = !showChardInfo() && (!dataInfo.appliedCoupon || !dataInfo.freeOfCharge);
        if (flag) {
            setTimeout(() => {
                window.jQuery('#card-element [data-stripe="number"]').payment('formatCardNumber');
                window.jQuery('#card-element [data-stripe="exp"]').payment('formatCardExpiry');
                window.jQuery('#card-element [data-stripe="cvc"]').payment('formatCardCVC');
            }, 0);
        }
        return flag;
    };

    const _onPayClick = (platform = 'paypal') => {
        // 添加埋点
        FJUtil.ealog('credits payment button click', {
            'credits quota': currentCheckedCreditPackage.base,
            price: couponBrickRef.current.getRealPrice(),
            'coupon code': dataInfo.appliedCoupon,
            'coupon code off':
                dataInfo.appliedCoupon && (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
            platform: platform,
        });
    };

    const _submit = () => {
        _onPayClick('stripe');
        // 没有积分包相关信息时，不执行相关接口（程序兼容报错）
        if (!currentCheckedCreditPackage || !currentCheckedCreditPackage.packageName) {
            return;
        }
        if (!FJUtil.checkIsLoginByNetwork()) {
            return;
        }
        if (FJUtil.isNewPay()) {
            if (dataInfo.freeOfCharge) {
                dispatch(FJStripePayAction.stripePayInCreditsAction({}));
            } else {
                if (loadStripeUIStatus !== FJViewConstant.SUCCESS) {
                    FJMessageStore.addMessage(FJLocalStore._('network-error-please-try-again-later'), 'error');
                    return;
                }
                formRef.current.confirmPayment(currentCheckedCreditPackage.packageName, {
                    type: FJViewConstant.CREDITS,
                });
            }
            return;
        }

        let subscription = (sendToken = true, packageName) => {
            dispatch(mainAction.changeLoadingStatusAction(true));
            window.FJGlobalariable.modSubscription.network
                .purchaseSubscription(packageName, sendToken, dataInfo.appliedCoupon, isTeamOwner, teamOwnerTid)
                .then(response => {
                    switch (response.code) {
                        case 200: {
                            // 打开支付成功的问卷页面
                            store.dispatch(mainAction.changeBuyCreditsSuccessPage(true));

                            const payInfo =
                                response.data && response.data.credits instanceof Object ? response.data.credits : {};

                            dispatch(
                                mainAction.setDataInfo({
                                    amount: payInfo.price,
                                    period_end: payInfo.period_end,
                                    sub_type: payInfo.sub_type,
                                    transaction_id: payInfo.transaction_id,
                                }),
                            );

                            // 订阅成功关闭折扣码
                            let obj = {};
                            obj.openDiscount = false;
                            store.dispatch(mainAction.setDiscountConfAction(obj));

                            window.FJGlobalariable.modSubscription.state.page = 'successful';

                            // 这里配合相关页面以及login-button等更新积分
                            // if (window.fj && window.fj.event && window.fj.event.dispatch) {
                            //     window.fj.event.dispatch('UPDATE_USER_CREDITS');
                            // }
                            setTimeout(() => {
                                FJUtil.notifyToUpdateCredit();
                            }, 5000);

                            FJUtil.ealog('ai credits revenue', {
                                'credit type':
                                    planType === 'personal'
                                        ? `p${currentCheckedCreditPackage.base}`
                                        : `t${currentCheckedCreditPackage.base}`,
                                'coupon code off':
                                    dataInfo.appliedCoupon &&
                                    (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                                'payment platform': 'stripe',
                                'coupon code': dataInfo.appliedCoupon,
                                location: cityCodeRef.current,
                                price: payInfo.price,
                                '6.0.7  ab text': 'old',
                                'ai tools': entry,
                            });

                            window.gaEvent('ai credits revenue', window.FJGlobalariable.modSubscription.entry, '');

                            break;
                        }
                        case 214:
                            FJUtil.checkLogin(response.code);
                            window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                            break;
                        case 335:
                            FJUtil.checkLogin(response.code);
                            break;
                        default:
                            // this.errorTexts.response = response.msg;
                            setResponse(response.msg);
                            if (window.fj.currentPlan.package === 'free') {
                                FJMessageStore.addMessage(FJLocalStore._('PLEASE_USE_PAYPAL'), 'error');
                                // window.fjmessage.addMessage(text_('SUB.PLEASE_USE_PAYPAL'), 'error');
                            }
                            break;
                    }
                })
                .catch(error => {
                    switch (error.type) {
                        case 'stripe':
                            setResponse(error.error.message);
                            // this.errorTexts.response = error.error.message;
                            break;
                        case 'ajax':
                        default:
                            setResponse(FJLocalStore._('CONNECT_NETWORK_FAILED'));
                        // this.errorTexts.response = text_('SUB.CONNECT_NETWORK_FAILED');
                    }
                })
                .finally(() => {
                    // this.isSubmitting = false;
                    // FJGlobalariable.modSubscription.showLoading = false;
                    dispatch(mainAction.changeLoadingStatusAction(false));
                });
        };

        // 判断是否展示了输入框，不需要输入卡号的话也不需要去拿strip的token
        const showForm = showChardForm();
        if (showForm) {
            // free 状态或者是升级时没有信用卡信息时，验证信用卡
            let { name, number, date, CVC } = formRef.current.getValue();
            if (!formRef.current.dataValid({ name, number, date, CVC })) {
                return;
            }
        }

        subscription(showForm, currentCheckedCreditPackage.packageName);
    };

    const _close = () => {
        _display();
        dispatch(mainAction.openOrCloseCreditsPayPanel(false));
    };

    let _display = () => {
        if (document.getElementById('pricing-view-container')) {
            // document.getElementById('pricing-view-container').style.display = 'none';
            document.getElementById('pricing-view-container').style.display = 'block';
        }
        // 将头部的两个切换按钮进行显示、隐藏切换
        if (!useNewCreditModal) {
            dispatch(mainAction.showOrHiddenPanelButton(true));
        }
    };
    let cityCodeRef = useRef('cn');
    let _getCityCode = () => {
        try {
            FJNetworkStore.getUserCountryCode().then(countryCode => {
                cityCodeRef.current = countryCode;
            });
        } catch (e) {}
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        _getCityCode();
    }, []);
    return (
        <div className={classNames(Style.bbox, { [Style.bbox_show]: true })}>
            <HeadBrick popupType={popupType} arrowClick={_close} closeClick={_close} />
            <div className={Style.content}>
                <div className={Style.left_box}>
                    <div className={Style.head}>
                        <img className={Style.banner} src={BANNERBase64} width={398} height={199} />
                        <div className={Style.title}>{FJLocalStore._('PAY_TITLE_CREDITS')}</div>
                        <div className={Style.price_container}>
                            <div className={Style.price_top}>
                                {__drawCredits()}
                                <p className={Style.credits_valid}>{FJLocalStore._('credits-valid')}</p>
                            </div>
                            <div className={Style.period_price}>{countPrice()}</div>
                        </div>
                    </div>
                    <div className={Style.feature_list_container}>
                        <div className={Style.feature_list_head}>{FJLocalStore._('credits-exchanged')}</div>
                        {__drawFeatureItem()}
                    </div>
                    <div className={Style.icon_tip}>
                        <div>
                            <InlineSVG src={shieldIcon} />
                            <span>{FJLocalStore._('PAY_SECURE')}</span>
                        </div>
                    </div>
                    <span
                        className={Style.other_faq}
                        dangerouslySetInnerHTML={{
                            __html: faq(),
                        }}
                    />
                </div>
                <div className={Style.right_box}>
                    <div className={Style.pay_title}>{FJLocalStore._('Payment-Details')}</div>
                    <PayMethod method={payMethod} change={_changePayMethod} />
                    <div
                        className={classNames(Style.card_box, {
                            [Style.card_box_info]: showChardInfo() && response,
                            [Style.card_box_form]: showChardForm() && response,
                        })}
                        style={payMethod === 'stripe' ? {} : { display: 'none' }}
                    >
                        {showChardInfo() && <CardInfoBrick />}
                        {showChardForm() && <CreditCardPanel ref={formRef} mode='payment' />}
                    </div>
                    {response && <div className={Style.response_box}>{response}</div>}
                    <div style={{ height: showChardInfo() ? '54px' : payMethod === 'stripe' ? '0' : '30px' }} />
                    <div style={{ paddingBottom: '12px' }}>
                        <CouponBrick
                            countPrice={currentCheckedCreditPackage}
                            isAnnually={true}
                            plan={currentCheckedCreditPackage}
                            active
                            ref={couponBrickRef}
                        />
                    </div>
                    <div
                        className={Style.card_btn}
                        style={payMethod === 'stripe' ? {} : { display: 'none' }}
                        onClick={_submit}
                    >
                        {FJLocalStore._('BUTTON_PAY_NOW')}
                    </div>
                    <PaypalBtn
                        isAnnually={true}
                        creditsPackage={currentCheckedCreditPackage}
                        style={payMethod === 'paypal' ? {} : { display: 'none' }}
                        paypalOnClick={_onPayClick}
                    />
                    <span
                        className={Style.terms}
                        dangerouslySetInnerHTML={{
                            __html: FJLocalStore._('PAY_TERMS_OF_USE').replace('%s', '/terms.html'),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default PayPanel;
