import Style from './PackagePanelContent.module.less';
import FJLocalStore from '../../store/FJLocalStore';
import ChangeButton from '../common/ChangeButton/ChangeButton';
import PackageItem from './PackageItem';
import classNames from 'classnames';
import CloseButton from '../common/CloseButton/CloseButton';
import PanelButton from '../PackagePanel/StaticContent/PanelButton/PanelButton';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import SeatsSelector from './SeatsSelector';
import FJUtil from '../../util/FJUtil';
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useINOneTimePayment } from '../../hooks/useINOneTimePayment';
import CreditsContainer from '../CreditsPanel/CreditsContainer';
import useIsMobile from '../../hooks/useIsMobile';

const SEATS_DATA = [
    {
        length: 3,
        title: '1-3',
    },
    {
        length: 5,
        title: '5',
    },
    {
        length: 10,
        title: '10',
    },
];

const PackagePanelContent = props => {
    let { popupType, planData, changeStep, isAnnually, changePlan, active, discountConf, planType } = props;

    const calculatorSeatCount = useSelector(state => state.mainReducer.calculatorSeatCount);
    const useNewCreditModal = useSelector(state => state.mainReducer.useNewCreditModal);
    const packageItemRefs = useRef([]);
    const [packageItemWidth, setPackageItemWidth] = useState(0);
    const currentCountry = useSelector(state => state.mainReducer.currentCountry);
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    const panelName = useSelector(state => state.mainReducer.panelName);
    const displayPanelButton = useSelector(state => state.mainReducer.displayPanelButton);

    packageItemRefs.current = [];

    useLayoutEffect(() => {
        let maxWidth = 0;
        packageItemRefs.current.forEach(item => {
            if (item) {
                // 50 是左右两边的 padding 和 border
                maxWidth = Math.max(maxWidth, item.getSize().width + 50);
            }
        });
        setPackageItemWidth(maxWidth);
        //  以下参数变化时重新计算
    }, [currentCountry, currentCountryMonetaryISO, exchangeRate, currentCountryMonetary, isAnnually, active]);

    // 是否是移动端
    let isMobile = useIsMobile();

    let dispatch = useDispatch();
    let _close = () => {
        dispatch(mainAction.closeSubscribeView());
        dispatch(mainAction.openOrCloseCreditsPayPanel(false)); // 关闭积分购买面板
        dispatch(mainAction.changeBuyCreditsSuccessPage(false)); // 关闭问卷页面
    };

    const isInOneTimepayment = useINOneTimePayment();

    // 根据当前用户所在地区，显示套餐支付提示
    // 印度用户显示：NO AUTO-RENEWAL
    const getPlanPaymentTip = useMemo(
        () => (isInOneTimepayment ? FJLocalStore._('NO_AUTO_RENEWAL') : ''),
        [isInOneTimepayment],
    );

    const getDisplayPlanData = useMemo(() => {
        if (planType === 'team') {
            return planData.filter(plan => plan.package === 'free' || plan.package === 'team');
        }
        const planDataWithoutTeam = planData.filter(plan => plan.package !== 'team');
        return popupType ? planDataWithoutTeam.filter(plan => plan.package !== 'free') : planDataWithoutTeam;
    }, [planType, planData, popupType]);

    const initialSelectedIndex = useMemo(
        () => SEATS_DATA.map(item => item.length).indexOf(calculatorSeatCount || 3),
        [calculatorSeatCount],
    );

    const _onChangeSeats = count => {
        dispatch(mainAction.changeTeamSeatCount(count));
    };

    let getDiscount = () => {
        let discount = planType === 'team' ? '33%' : '40%';
        if (discountConf.openDiscount) {
            let lists = discountConf.lists;
            if (lists) {
                let minDiscountChannel;
                for (let i = 0; i < lists.length; i++) {
                    if (lists[i].discountChannel && lists[i].discountChannel !== 0) {
                        if (minDiscountChannel === undefined || lists[i].discountChannel < minDiscountChannel) {
                            minDiscountChannel = 100 - lists[i].discountChannel;
                        }
                    }
                }
                discount = minDiscountChannel + '%';
            }
        }
        return discount;
    };

    let discount = getDiscount();

    const showCloseBtn = !useNewCreditModal && popupType;

    return (
        <div className={classNames(Style.bbox, { [Style.bbox_pop]: popupType, [Style.bbox_show]: active })}>
            {panelName === 'Credits' && (
                <>
                    {showCloseBtn && (
                        <div className={Style.popup_type_close}>
                            <CloseButton onClick={_close} />
                        </div>
                    )}
                    {displayPanelButton && <PanelButton boxStyle={Style.popup_button} />}
                    <CreditsContainer />
                </>
            )}
            {panelName === 'Subscription' && (
                <>
                    {popupType && (
                        <div className={Style.popup_type_close}>
                            <CloseButton onClick={_close} />
                        </div>
                    )}
                    {displayPanelButton && popupType && <PanelButton boxStyle={Style.popup_button} />}
                    <p className={Style.plan_title}>{FJLocalStore._('PLANS_TITLE')}</p>
                    <div className={Style.plansPeriod}>
                        <div className={classNames(Style.text, Style.monthBox)}>
                            {FJLocalStore._('PAY_BILLED_MONTHLY')}
                        </div>
                        <ChangeButton onClick={changePlan} value={isAnnually} />
                        <div className={Style.plansPeriodRightBox}>
                            <div className={classNames(Style.text, Style.annuallyBox)}>
                                {FJLocalStore._('PAY_BILLED_ANNUALLY')}
                                {!isMobile && (
                                    <div className={Style.cut_tip_phone}>
                                        <span>{FJLocalStore._('SAVE_UP_TO').replace('xxx', discount)}</span>
                                        <div className={Style.rotate_icon}>
                                            <svg
                                                width='10px'
                                                height='31px'
                                                viewBox='0 0 10 31'
                                                version='1.1'
                                                xmlns='http://www.w3.org/2000/svg'
                                            >
                                                <g
                                                    id='Page-1'
                                                    stroke='none'
                                                    strokeWidth='1'
                                                    fill='none'
                                                    fillRule='evenodd'
                                                >
                                                    <g
                                                        id='pricing-iPhone-12'
                                                        transform='translate(-351.000000, -47.000000)'
                                                        fill='#FEE779'
                                                    >
                                                        <path
                                                            d='M368.031063,60.3976359 C367.543954,59.0836205 366.420729,58.2114151 365.932284,56.9726014 C366.390661,56.5140053 366.706046,56.8121264 366.920534,57.0337028 C368.046431,58.1953004 369.140255,59.3938275 369.944753,60.8085594 C370.534764,61.8452686 370.341657,62.2467919 369.164978,62.4945546 C368.428635,62.6496581 367.670909,62.7188168 366.91853,62.7805897 C366.222946,62.8369909 365.50598,62.6865875 364.856501,63.0840822 C364.433538,63.3432595 364.206354,63.0464814 364.014584,62.7282171 C363.751986,62.2917786 363.878942,61.9043556 364.30391,61.7176942 C364.934679,61.4410594 365.604872,61.2543981 366.432757,60.9697059 C364.999493,60.2163461 363.630376,59.8819671 362.258586,59.7282065 C354.937917,58.9070308 348.459834,60.6447272 343.29474,66.2257675 C342.462177,67.1248306 341.731179,68.0809665 341.470586,69.304337 C341.344299,69.9019219 341.028246,69.7575615 340.720879,69.560157 C340.218402,69.2365211 340.225083,68.7141379 340.315289,68.1977977 C340.48434,67.2262186 340.969445,66.409743 341.543419,65.6214681 C344.699939,61.2913275 349.057194,58.987604 354.208256,58.0489257 C356.754052,57.5856295 359.314549,57.5574289 361.881728,57.9596237 C364.110135,58.3087744 366.148777,59.0970494 368.031063,60.3976359'
                                                            id='Fill-1'
                                                            transform='translate(355.268136, 63.231864) rotate(110.000000) translate(-355.268136, -63.231864) '
                                                        />
                                                    </g>
                                                </g>
                                            </svg>
                                        </div>
                                    </div>
                                )}
                            </div>
                            <div className={Style.cut_tip}>
                                <span>{FJLocalStore._('SAVE_UP_TO').replace('xxx', discount)}</span>
                                <div className={Style.rotate_icon}>
                                    <svg width='46px' height='17px' viewBox='0 0 46 17' version='1.1'>
                                        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                            <g
                                                id='pricing-final-copy'
                                                transform='translate(-1078.000000, -217.000000)'
                                                fill='#FEE779'
                                            >
                                                <path
                                                    d='M1120.54957,221.459537 C1119.82057,219.502537 1118.13957,218.203537 1117.40857,216.358537 C1118.09457,215.675537 1118.56657,216.119537 1118.88757,216.449537 C1120.57257,218.179537 1122.20957,219.964537 1123.41357,222.071537 C1124.29657,223.615537 1124.00757,224.213537 1122.24657,224.582537 C1121.14457,224.813537 1120.01057,224.916537 1118.88457,225.008537 C1117.84357,225.092537 1116.77057,224.868537 1115.79857,225.460537 C1115.16557,225.846537 1114.82557,225.404537 1114.53857,224.930537 C1114.14557,224.280537 1114.33557,223.703537 1114.97157,223.425537 C1115.91557,223.013537 1116.91857,222.735537 1118.15757,222.311537 C1116.01257,221.189537 1113.96357,220.691537 1111.91057,220.462537 C1100.95457,219.239537 1091.25957,221.827537 1083.52957,230.139537 C1082.28357,231.478537 1081.18957,232.902537 1080.79957,234.724537 C1080.61057,235.614537 1080.13757,235.399537 1079.67757,235.105537 C1078.92557,234.623537 1078.93557,233.845537 1079.07057,233.076537 C1079.32357,231.629537 1080.04957,230.413537 1080.90857,229.239537 C1085.63257,222.790537 1092.15357,219.359537 1099.86257,217.961537 C1103.67257,217.271537 1107.50457,217.229537 1111.34657,217.828537 C1114.68157,218.348537 1117.73257,219.522537 1120.54957,221.459537'
                                                    id='Fill-1'
                                                    transform='translate(1101.448769, 225.680633) rotate(5.000000) translate(-1101.448769, -225.680633) '
                                                />
                                            </g>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className={classNames(Style.list_scroll, {
                            [Style.popup]: popupType,
                            [Style.list_scroll_in_mobile]: isMobile,
                        })}
                    >
                        <div className={Style.list}>
                            {getDisplayPlanData.map((item, index) => {
                                return (
                                    <PackageItem
                                        ref={ref => {
                                            packageItemRefs.current.push(ref);
                                        }}
                                        index={index}
                                        key={item.name}
                                        popupType={popupType}
                                        itemData={item}
                                        packageItemWidth={packageItemWidth}
                                        changeStep={changeStep}
                                        isAnnually={isAnnually}
                                    />
                                );
                            })}
                        </div>
                    </div>
                    {getPlanPaymentTip && (
                        <div className={Style.planPaymentTip}>
                            <img
                                src='https://resource.flexclip.com//pages/company/pricing/icon-flash.webp'
                                width={24}
                                height={24}
                                loading='lazy'
                                alt='no auto-renewal icon'
                            />
                            <p>{getPlanPaymentTip}</p>
                        </div>
                    )}
                    {planType !== 'team' ? (
                        <div className={Style.team_panel_container}>
                            <div className={classNames(Style.team_panel, { [Style.popup]: popupType })}>
                                <div className={Style.title}>{FJLocalStore._('team_panel_title')}</div>
                                <SeatsSelector
                                    seatsData={SEATS_DATA}
                                    initialSelectedIndex={initialSelectedIndex}
                                    onChangeSeats={_onChangeSeats}
                                />
                                <a
                                    className={Style.create_team_btn}
                                    href={
                                        FJUtil.getTeamPricingUri() + `?calculatorSeatCount=${calculatorSeatCount || 3}`
                                    }
                                    target='_blank'
                                >
                                    {FJLocalStore._('Check-team-plan')}
                                </a>
                            </div>
                        </div>
                    ) : null}
                </>
            )}
        </div>
    );
};
export default PackagePanelContent;
