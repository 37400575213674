import Style from './Credits.module.less';
import classnames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import mainAction from '../../../reducer/action/mainAction';
import FJLocalStore from '../../../store/FJLocalStore';
import FJUtil from '../../../util/FJUtil';
import useIsMobile from '../../../hooks/useIsMobile';
import FJCreditsPackageViewContainer from '../../FJMobile/FJCreditsPackageView/FJCreditsPackageView.container';
import usePricePrefix from '../../../hooks/usePricePrefix';

export default function Credits(props) {
    // 是否是移动端，是的话，仅对积分套餐做展示，不做交互
    let isMobile = useIsMobile();
    const pricePrefix = usePricePrefix();
    const dispatch = useDispatch();

    const { purchase = [], getUserInfo = () => {}, changeCheckedCredits } = props;
    const creditsPayData = useSelector(state => state.mainReducer.creditsPayData);
    const method = useSelector(state => state.mainReducer.method);
    const isPopup = method === 'popup';

    // 当前用户所在地区货币符号
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const checkedPurchase = creditsPayData.index;
    let __drawPurchaseView = () => {
        if (isMobile) {
            return <FJCreditsPackageViewContainer />;
        }
        let purchaseItemClick = index => {
            if (checkedPurchase === index) {
                return;
            }
            changeCheckedCredits(index);
        };

        return (
            <ul className={Style.purchase}>
                {Array.isArray(purchase) &&
                    purchase.map((item, index) => {
                        const realPrice = FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2);
                        const pricePerCredit = (Number(realPrice) / Number(item.base)).toFixed(3);
                        return (
                            <li
                                key={index}
                                className={classnames(Style.purchaseItem, {
                                    [Style.purchaseItemActive]: checkedPurchase === index,
                                })}
                                onClick={() => {
                                    purchaseItemClick(index);
                                }}
                            >
                                <div className={Style.outerBox}>
                                    <div className={Style.purchaseItemLeft}>
                                        <div className={Style.purchaseItemIconBox}>
                                            <img
                                                src='https://resource.flexclip.com/app/subscription/img/Credits-26.webp'
                                                alt='coin'
                                                width={26}
                                                height={26}
                                            />
                                            <span>{item.base}</span>
                                        </div>
                                        <div className={Style.pricePerCredit}>
                                            {currentCountryMonetary}
                                            {currentCountryMonetaryISO}
                                            &nbsp;
                                            {FJLocalStore._('dollar-per-credit').replace('xxx', pricePerCredit)}
                                        </div>
                                    </div>
                                    <div className={Style.purchaseItemPrice}>
                                        <span className={Style.currentCountryMonetary}>
                                            {currentCountryMonetary || item.priceUnit}
                                            {currentCountryMonetaryISO}
                                        </span>
                                        <span className={Style.currentCountryMonetaryPrice}>
                                            {FJUtil.formatNumberThousandth(realPrice)}
                                        </span>
                                    </div>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        );
    };

    const isInPersonalSpace = window.fjuser?.info?.space_type === 'personal';
    const isInTeamSpace = window.fjuser?.info?.space_type === 'team';
    const planType = useSelector(state => state.mainReducer.planType);
    // 选择积分套餐后点击跳转去支付
    const chooseCreditsAndToPayPanel = () => {
        if (typeof checkedPurchase !== 'number') {
            return;
        }

        /**
         * 个人空间时
         * 1.个人积分面板可以直接购买个人积分
         * 2.团队积分面板只能购买名下拥有的团队积分，如果没有团队会提示先创建一个团队
         */
        if (isInPersonalSpace) {
            // 情况1直接放行至后续函数
            // 情况2
            if (planType === 'team') {
                const space_items = window.fjuser?.info?.space_items || [];
                if (space_items.length === 0) {
                    window.fjmessage.addMessage(FJLocalStore._('create-team-first'), 'warning');
                    return;
                }
                const ownTeam = space_items.find(item => item.spacePermission === 'owner');
                if (!ownTeam) {
                    window.fjmessage.addMessage(FJLocalStore._('member-cant-buy-credit'), 'warning');
                    return;
                }
                dispatch(mainAction.setIsTeamOwner(true));
                dispatch(mainAction.setTeamOwnerTid(ownTeam.id));
            }
        }
        /**
         * 团队空间时
         * 1.无论成员还是拥有者，个人积分面板都可以直接购买个人积分
         * 2.团队积分面板只有拥有者能买团队积分，成员会提示不能买团队积分
         */
        if (isInTeamSpace && planType === 'team') {
            const permission = window.fjuser?.info?.space_items.find(
                item => item.id === window.fjuser.info.current_space_id,
            ).spacePermission;
            if (permission !== 'owner') {
                window.fjmessage.addMessage(FJLocalStore._('member-cant-buy-credit'), 'warning');
                return;
            }
            dispatch(mainAction.setIsTeamOwner(true));
            dispatch(mainAction.setTeamOwnerTid(window.fjuser.info.current_space_id));
        }

        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        getUserInfo && getUserInfo();
    };

    const jumpToPricingCredits = () => {
        const url = FJUtil.getCurrentUrl() + '/pricing?panelName=Credits';
        window.open(url, '_blank');
    };
    const boxStyle = isPopup
        ? {
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%,-50%)',
          }
        : {};
    const purchaseInfo = creditsPayData.info;
    const price = purchaseInfo ? pricePrefix + purchaseInfo.price : '';
    const btnText = isMobile ? FJLocalStore._('get-now') + ' ' + price : FJLocalStore._('get-now');

    return (
        <div className={Style.Credits} style={boxStyle}>
            <div>
                <h2 className={Style.title}>{FJLocalStore._('credits-title')}</h2>
                <p className={Style.desc} dangerouslySetInnerHTML={{ __html: FJLocalStore._('credits-desc') }} />
                {__drawPurchaseView()}
            </div>
            <button className={Style.startNowBtn} onClick={chooseCreditsAndToPayPanel}>
                {btnText}
            </button>
            <p className={Style.creditsValidity}>
                {FJLocalStore._('CreditsValidity')}&nbsp;
                {isPopup && <span onClick={jumpToPricingCredits}>{FJLocalStore._('lern-more')}</span>}
            </p>
        </div>
    );
}
