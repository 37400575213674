/* eslint-disable max-depth */
import Style from './PayPanel.module.less';
import InlineSVG from 'react-inlinesvg';
import rightIcon from '../../icon/right.svg';
import payIcon from '../../icon/money-recive.svg';
import shieldIcon from '../../icon/secure.svg';
import FJLocalStore from '../../store/FJLocalStore';
import PayMethod from '../../PayMethod/PayMethod';
import { useState, useRef } from 'react';
import CreditCardPanel from '../CreditCardPanel/CreditCardPanel';
import { useDispatch, useSelector } from 'react-redux';
import mainAction from '../../reducer/action/mainAction';
import CouponBrick from '../ConponBrick/CouponBrick';
import HeadBrick from '../HeadBrick/HeadBrick';
import classNames from 'classnames';
import CardInfoBrick from '../CardInfoBrick/CardInfoBrick';
import PaypalBtn from '../PaypalBtn/PaypalBtn';
import FJMessageStore from '../../store/FJMessageStore';
import store from '../../reducer/reducer/FJRoot';
import FJNetworkStore from '../../store/FJNetworkStore';
import FJViewConstant from '../../constant/FJViewConstant';
import { AUTO_SUBTITLE_PACKAGE_QUOTA, BACKGROUND_REMOVAL_PACKAGE_QUOTA } from '../../constant/FJValueConstant';
import ChangeButton from '../common/ChangeButton/ChangeButton';
import TeamSeatsPanel from './TeamSeatsPanel';
import starIcon from '../../icon/star.svg';
import FJUtil from '../../util/FJUtil';
import { useINOneTimePayment } from '../../hooks/useINOneTimePayment';
import TEAM_BANNER from '../../assets/base64/team-banner-base64';
import BANNER from '../../assets/base64/banner-base64';
import FJStripePayAction from '../../reducer/action/FJStripePayAction';

const PayPanel = props => {
    let { changeStep, changePlan, planDetail, isAnnually, popupType, active } = props;
    const [response, setResponse] = useState('');
    let formRef = useRef(null);
    const couponBrickRef = useRef(null);

    let dispatch = useDispatch();

    const step = useSelector(state => state.mainReducer.step);
    const payMethod = useSelector(state => state.mainReducer.payMethod);
    const currentPlan = useSelector(state => state.mainReducer.currentPlan);
    const dataInfo = useSelector(state => state.mainReducer.dataInfo);
    const selectPage = useSelector(state => state.mainReducer.selectPage);
    const planType = useSelector(state => state.mainReducer.planType);
    const seatCount = useSelector(state => state.mainReducer.seatCount);
    const additionalSeatCount = useSelector(state => state.mainReducer.additionalSeatCount);
    const teamSubscription = useSelector(state => state.mainReducer.teamSubscription);
    const discountConf = useSelector(state => state.mainReducer.discountConf);

    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);

    const loadStripeUIStatus = useSelector(state => state.mainReducer.loadStripeUIStatus);
    const useNewCreditModal = useSelector(state => state.mainReducer.useNewCreditModal);
    const useNewCreditModalABtest = useSelector(state => state.mainReducer.useNewCreditModalABtest);
    const subThroughNewModal = useNewCreditModal && useNewCreditModalABtest;

    let onChangeSeats = val => {
        dispatch(mainAction.updateSeatCount(val));
    };

    let { name, price, package: packageType, seatPrice } = planDetail;
    const banner = packageType === 'team' ? TEAM_BANNER : BANNER;

    let _changePayMethod = method => {
        dispatch(mainAction.changePayMethodAction(method));
    };
    let _close = () => {
        dispatch(mainAction.closeSubscribeView());
        window.FJGlobalariable.modSubscription.closeSubscriptionCallback();
    };
    let _drawItem = () => {
        return planDetail.shortFeatures.map(item => {
            let text = '';
            switch (item.key) {
                case FJViewConstant.AUTO_SUBTITLE: {
                    const quota = AUTO_SUBTITLE_PACKAGE_QUOTA[planDetail.package];
                    const yearQuota = quota * 12;
                    text =
                        isAnnually && planDetail.package !== 'free'
                            ? FJLocalStore._(item.yearText).replace('xxx', yearQuota)
                            : FJLocalStore._(item.monthText).replace('xxx', quota);
                    break;
                }
                case FJViewConstant.BACKGROUND_REMOVAL: {
                    text = FJLocalStore._(item.text).replace(
                        'xxx',
                        BACKGROUND_REMOVAL_PACKAGE_QUOTA[planDetail.package],
                    );
                    break;
                }
                case FJViewConstant.AI_QUOTA: {
                    text = FJLocalStore._(item.text).replace('xxx', FJUtil.packageName(planDetail.package));
                    break;
                }
                default:
                    text = FJLocalStore._(item.text);
            }
            return (
                <div key={item.text} className={Style.featureItem}>
                    <div className={Style.icon_box}>
                        <InlineSVG src={rightIcon} />
                    </div>
                    <span className={Style.text_box}>{text}</span>
                </div>
            );
        });
    };
    let showChardInfo = () => {
        if (FJUtil.isNewPay()) {
            return false;
        }
        if (planType === 'team') {
            return teamSubscription?.last4;
        }
        return currentPlan.last4;
    };
    let showChardForm = () => {
        let flag =
            !showChardInfo() &&
            (!dataInfo.appliedCoupon || !dataInfo.freeOfCharge) &&
            (FJUtil.isNewPay() && !isInOneTimepayment
                ? !isUpgrade() || (isUpgrade() && !window?.fj?.exchangeRate?.stripe_customer_has_card)
                : true);
        if (flag) {
            setTimeout(() => {
                window.jQuery('#card-element [data-stripe="number"]').payment('formatCardNumber');
                window.jQuery('#card-element [data-stripe="exp"]').payment('formatCardExpiry');
                window.jQuery('#card-element [data-stripe="cvc"]').payment('formatCardCVC');
            }, 0);
        }
        return flag;
    };
    let selectPeriod = () => {
        return isAnnually ? 'annual' : 'monthly';
    };
    let totalPrice = () => {
        if (selectPeriod() === 'monthly') return price.monthly.toFixed(2);
        else {
            return (price.annual * 12).toFixed(2);
        }
    };
    let realPrice = () => {
        if (dataInfo.appliedCoupon && dataInfo.couponObject) {
            let amount = totalPrice;
            if (dataInfo.couponObject.amount_off) {
                amount = (amount - dataInfo.couponObject.amount_off).toFixed(2);
            } else if (dataInfo.couponObject.percent_off) {
                amount = ((1 - parseFloat(dataInfo.couponObject.percent_off) / 100) * amount).toFixed(2);
            }

            if (amount < 0) amount = 0;
            return amount;
        } else {
            return totalPrice;
        }
    };
    let _getPlan = () => {
        let selectPeriod = isAnnually ? 'annual' : 'monthly';
        let selectPackage = planDetail.backendPackage;
        const plan = {
            plan: selectPackage + '_' + selectPeriod,
        };
        if (selectPage === 'team' && teamSubscription) {
            plan.teamId = teamSubscription.id;
            plan.seats = additionalSeatCount ?? seatCount;
        }
        return plan;
    };
    let isUpgrade = () => {
        if (planType === 'team') {
            return (
                teamSubscription &&
                teamSubscription.package !== 'free' &&
                teamSubscription.sub_type !== 'exch' &&
                teamSubscription.status !== 'promotion'
            );
        }
        return currentPlan.package !== 'free' && currentPlan.sub_type !== 'exch' && currentPlan.status !== 'promotion';
    };
    let refreshInvoices = () => {
        // 刷新账单列表
        if (window.fj.billing && window.fj.billing.chargeHistory) {
            window.FJGlobalariable.modSubscription.network
                .updateInvoices()
                .then(res => {
                    if (res.data.length === window.fj.billing.chargeHistory.length) {
                        setTimeout(() => {
                            refreshInvoices();
                        }, 3000);
                    } else {
                        window.fj.billing.chargeHistory = res.data;
                    }
                })
                .catch(error => {})
                .finally(() => {});
        }
    };
    let getCookie = name => {
        let arr;
        let reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if (arr === document.cookie.match(reg)) return unescape(arr[2]);
        else return null;
    };
    let faq = () => {
        let text = FJLocalStore._('PAY_FAQS');
        if (window.FJGlobalariable.NoJumpFAQPages) {
            text = text.replace('%s', '/contact.html');
            text = text.replace('%s', 'u-link-gray');
        } else {
            text = text.replace('%s', '/faq.html');
            text = text.replace('%s', 'u-link-gray');
            text = text.replace('%s', '/contact.html');
            text = text.replace('%s', 'u-link-gray');
        }
        return text;
    };

    const isInOneTimepayment = useINOneTimePayment();
    const boxTypeDescribe = sessionStorage.getItem('6.2.0boxTypeDesc');

    const _onPayClick = (platform = 'paypal') => {
        const planData = _getPlan();

        // 添加埋点
        FJUtil.ealog('subscribe payment button click', {
            'plan type': isUpgrade() ? currentPlan.package + '_' + selectPeriod() + '-' + planData.plan : planData.plan,
            price: couponBrickRef.current.getRealPrice(),
            'coupon code': dataInfo.appliedCoupon,
            'coupon code off':
                dataInfo.appliedCoupon && (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
            platform: platform,
        });

        FJUtil.ealog('regions pricing AB_new revenue_2024-12', {
            plan: selectPage + (isAnnually ? '_y' : '_m'),
            AB: window.uid ? (window.uid % 2 === 0 ? 'B' : 'A') : 'undefined',
            prcing: couponBrickRef.current.getRealPrice(),
            'coupon code': dataInfo.appliedCoupon,
        });
        console.log('price', price, 'seatPrice', seatPrice);
    };

    let _submitPay = () => {
        if (!FJUtil.checkIsLoginByNetwork()) {
            return;
        }
        _onPayClick('stripe');
        let planData = _getPlan();
        let name = '';
        setResponse('');

        const successFn = response => {
            let subscription =
                selectPage === 'team' ? { ...response.data.teamSubscription } : { ...response.data.subscription };
            window.FJGlobalariable.modSubscription.updateSubscriptionInfo(subscription);

            if (selectPage === 'team') {
                store.dispatch(mainAction.setTeamSubscription(subscription));
                return subscription;
            } else {
                store.dispatch(
                    mainAction.setCurrentPlan({
                        package: subscription.package,
                        period: subscription.period,
                        last4: subscription.last4,
                        price: subscription.price,
                        period_end: subscription.period_end,
                        status: subscription.status,
                        sub_type: subscription.sub_type ? subscription.sub_type : 'stripe',
                    }),
                );
            }

            window.FJGlobalariable.modSubscription.state.amount = subscription.amount;
            window.FJGlobalariable.modSubscription.state.coupon = dataInfo.appliedCoupon;
            window.FJGlobalariable.modSubscription.state.discount = totalPrice() - realPrice();
            window.FJGlobalariable.modSubscription.state.transaction_id = subscription.transaction_id;

            return subscription;
        };
        const successCreateFn = subscription => {
            dispatch(
                mainAction.setDataInfo({
                    coupon: dataInfo.appliedCoupon,
                    discount: totalPrice() - realPrice(),
                    amount: subscription.amount,
                    transaction_id: subscription.transaction_id,
                    appliedCoupon: null,
                }),
            );

            // 订阅成功关闭折扣码
            let obj = {};
            obj.openDiscount = false;
            store.dispatch(mainAction.setDiscountConfAction(obj));

            window.FJGlobalariable.modSubscription.state.page = 'successful';
            dispatch(mainAction.changeStepAction(3));
            refreshInvoices();
            // 统计事件
            if (window.ealog && window.ealog.setUserProperties) {
                let showSimpleTimeline = getCookie(window.fjuser.info.id + '_close_simple_timeline');
                let editor_mode = window.fjuser.info.is_simple_timeline
                    ? 'simple timeline'
                    : showSimpleTimeline
                    ? 'storyboard'
                    : '';
                window.ealog && window.ealog.setUserProperties({ 'editor mode': editor_mode });
                if (selectPage === 'team') {
                    window.ealog.setUserProperties({
                        'team type': subscription.package + ' ' + subscription.period,
                    });
                } else {
                    window.ealog.setUserProperties({
                        'account type': subscription.package + ' ' + subscription.period,
                    });
                }
            }

            window.ealog &&
                window.ealog.addEvent('revenue', {
                    price: subscription.amount / 100,
                    pay_type: planData.plan,
                    'is coupon code': dataInfo.appliedCoupon ? true : false,
                    'coupon code off':
                        dataInfo.appliedCoupon &&
                        (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                    'coupon code': dataInfo.appliedCoupon,
                    'payment platform': 'stripe',
                    '6.0.7  ab text': 'old',
                    ...(subThroughNewModal && { 'free account ab test 24.12.16': 'free credits get window A-switch' }),
                    ...(boxTypeDescribe && { 'free account ab test 24.12.16': boxTypeDescribe }),
                });
            window.gaEvent('subscription_created', window.FJGlobalariable.modSubscription.entry, '');
            window.gaPage('subscription/' + planData.plan + '.html', 'Subscription Created');
        };
        const successUpgradeFn = (subscription, old_plan) => {
            dispatch(
                mainAction.setDataInfo({
                    coupon: dataInfo.appliedCoupon,
                    discount: totalPrice() - realPrice(),
                    amount: subscription.amount,
                    transaction_id: subscription.transaction_id,
                }),
            );

            window.FJGlobalariable.modSubscription.state.page = 'successful';
            dispatch(mainAction.changeStepAction(3));
            refreshInvoices();

            // 统计事件
            if (window.ealog && window.ealog.setUserProperties) {
                let showSimpleTimeline = getCookie(window.fjuser.info.id + '_close_simple_timeline');
                let editor_mode = window.fjuser.info.is_simple_timeline
                    ? 'simple timeline'
                    : showSimpleTimeline
                    ? 'storyboard'
                    : '';
                window.ealog && window.ealog.setUserProperties({ 'editor mode': editor_mode });
                if (selectPage === 'team') {
                    window.ealog.setUserProperties({
                        'team type': subscription.package + ' ' + subscription.period,
                    });
                } else {
                    window.ealog.setUserProperties({
                        'account type': subscription.package + ' ' + subscription.period,
                    });
                }
            }

            window.ealog &&
                window.ealog.addEvent('revenue', {
                    price: subscription.amount / 100,
                    pay_type: old_plan + '-' + planData.plan,
                    'is coupon code': dataInfo.appliedCoupon ? true : false,
                    'coupon code off':
                        dataInfo.appliedCoupon &&
                        (dataInfo.couponObject.amount_off || dataInfo.couponObject.percent_off),
                    'coupon code': dataInfo.appliedCoupon,
                    'payment platform': 'stripe',
                    '6.0.7  ab text': 'old',
                });
            window.gaEvent('subscription_upgrade', window.FJGlobalariable.modSubscription.entry, '');
            window.gaPage('subscription/' + old_plan + '-' + planData.plan + '.html', 'Subscription Upgrade');
        };
        const failWithCreateCode = (code, msg) => {
            switch (code) {
                case 214:
                    FJUtil.checkLogin(code);
                    window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                    break;
                case 335:
                    FJUtil.checkLogin(code);
                    break;
                default:
                    setResponse(msg);
                    if (window.fj.currentPlan.package === 'free') {
                        FJMessageStore.addMessage(FJLocalStore._('PLEASE_USE_PAYPAL'), 'error');
                    }
            }
        };
        const failWithUpgradeCode = code => {
            switch (code) {
                case 214:
                    FJUtil.checkLogin(code);
                    window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                    break;
                case 335:
                    FJUtil.checkLogin(code);
                    window.FJGlobalariable.modSubscription.noLoginResponseCallback();
                    break;
                default:
                    break;
            }
        };

        let upgrade = () => {
            window.FJGlobalariable.modSubscription.showLoading = true;
            dispatch(mainAction.changeLoadingStatusAction(true));
            let country = 'unknown';
            FJNetworkStore.getUserCountryCode()
                .then(countryCode => {
                    country = countryCode;
                })
                .catch(e => {})
                .finally(() => {
                    window.FJGlobalariable.modSubscription.network
                        .upgrade(planData, dataInfo.appliedCoupon, country)
                        .then(response => {
                            let old_plan;
                            if (selectPage === 'team') {
                                old_plan = teamSubscription.package + '_' + teamSubscription.period;
                            } else {
                                old_plan = window.fj.currentPlan.package + '_' + window.fj.currentPlan.period;
                            }
                            switch (response.code) {
                                case 200:
                                    // 升级成功
                                    FJUtil.notifyToUpdateCredit();
                                    successUpgradeFn(successFn(response), old_plan);
                                    break;
                                default:
                                    failWithUpgradeCode(response.code);
                            }
                        })
                        .catch(error => {
                            setResponse(FJLocalStore._('CONNECT_NETWORK_FAILED'));
                            // this.errorTexts.response = text_('SUB.CONNECT_NETWORK_FAILED');
                        })
                        .finally(() => {
                            // this.isSubmitting = false;
                            // FJGlobalariable.modSubscription.showLoading = false;
                            dispatch(mainAction.changeLoadingStatusAction(false));
                        });
                });
        };

        let subscription = (sendToken = true) => {
            // let {name} = formRef.current.getValue()
            // window.FJGlobalariable.modSubscription.showLoading = true;
            dispatch(mainAction.changeLoadingStatusAction(true));
            let country = 'unknown';
            FJNetworkStore.getUserCountryCode()
                .then(countryCode => {
                    country = countryCode;
                })
                .catch(e => {})
                .finally(() => {
                    window.FJGlobalariable.modSubscription.network
                        .subscription(planData, name, country, sendToken, dataInfo.appliedCoupon)
                        .then(response => {
                            switch (response.code) {
                                case 200:
                                    setTimeout(() => {
                                        FJUtil.notifyToUpdateCredit();
                                    }, 5000);
                                    successCreateFn(successFn(response));
                                    break;
                                default:
                                    failWithCreateCode(response.code, response.msg);
                            }
                        })
                        .catch(error => {
                            switch (error.type) {
                                case 'stripe':
                                    setResponse(error.error.message);
                                    // this.errorTexts.response = error.error.message;
                                    break;
                                case 'ajax':
                                default:
                                    setResponse(FJLocalStore._('CONNECT_NETWORK_FAILED'));
                                // this.errorTexts.response = text_('SUB.CONNECT_NETWORK_FAILED');
                            }
                        })
                        .finally(() => {
                            // this.isSubmitting = false;
                            // FJGlobalariable.modSubscription.showLoading = false;
                            dispatch(mainAction.changeLoadingStatusAction(false));
                        });
                });
        };

        const subOrUpgradeIN = (sendToken = true) => {
            dispatch(mainAction.changeLoadingStatusAction(true));
            let country = 'unknown';
            FJNetworkStore.getUserCountryCode()
                .then(countryCode => {
                    country = countryCode;
                })
                .catch(e => {})
                .finally(() => {
                    const { plan, teamId, seats } = planData;
                    window.FJGlobalariable.modSubscription.network
                        .oneTimePayment(plan, dataInfo.appliedCoupon, {
                            name,
                            countryCode,
                            sendToken,
                            seats,
                            team_id: teamId,
                        })
                        .then(response => {
                            const { data } = response;
                            if (data && data.is_upgrade) {
                                let old_plan;
                                if (selectPage === 'team') {
                                    old_plan = teamSubscription.package + '_' + teamSubscription.period;
                                } else {
                                    old_plan = window.fj.currentPlan.package + '_' + window.fj.currentPlan.period;
                                }

                                // 升级成功
                                switch (response.code) {
                                    case 200:
                                        successUpgradeFn(successFn(response), old_plan);
                                        break;
                                    default:
                                        failWithUpgradeCode(response.code);
                                }
                            } else {
                                // 订阅成功
                                switch (response.code) {
                                    case 200:
                                        successCreateFn(successFn(response));
                                        break;
                                    default:
                                        failWithCreateCode(response.code, response.msg);
                                }
                            }
                        })
                        .catch(error => {
                            switch (error.type) {
                                case 'stripe':
                                    setResponse(error.message);
                                    break;
                                default:
                                    setResponse(FJLocalStore._('CONNECT_NETWORK_FAILED'));
                            }
                        })
                        .finally(() => {
                            dispatch(mainAction.changeLoadingStatusAction(false));
                        });
                });
        };

        if (FJUtil.isNewPay()) {
            // 100%折扣码
            if (dataInfo.freeOfCharge) {
                if (isInOneTimepayment) {
                    dispatch(FJStripePayAction.stripePayWithOneOffAction({}, { planData }));
                } else {
                    dispatch(FJStripePayAction.stripePayAction({}, { planData }));
                }
            } else if (isUpgrade() && window?.fj?.exchangeRate?.stripe_customer_has_card && !isInOneTimepayment) {
                dispatch(FJStripePayAction.stripePayAction({}, { planData }));
            } else {
                if (loadStripeUIStatus !== FJViewConstant.SUCCESS) {
                    FJMessageStore.addMessage(FJLocalStore._('network-error-please-try-again-later'), 'error');
                    return;
                }
                formRef.current.confirmPayment(planData, {
                    type: isInOneTimepayment ? FJViewConstant.ONE_TIME_PAYMENT : '',
                });
            }
        } else {
            if (showChardForm()) {
                // free 状态或者是升级时没有信用卡信息时，验证信用卡
                let { name, number, date, CVC } = formRef.current.getValue();

                if (!formRef.current.dataValid({ name, number, date, CVC })) {
                    // this.isSubmitting = false;
                    return;
                } else {
                    if (isInOneTimepayment) {
                        subOrUpgradeIN();
                    } else if (isUpgrade()) {
                        const teamId = planType === 'team' ? teamSubscription?.id : null;
                        // 没有信用卡的升级，需要先更新信用卡信息
                        window.FJGlobalariable.modSubscription.network
                            .changeCard('#card-element', teamId)
                            .then(response => {
                                // 修改成功
                                if (response.code === 200) {
                                    let last4 = response.data.last4;
                                    if (planType === 'team' && teamSubscription) {
                                        dispatch(
                                            mainAction.setTeamSubscription({
                                                ...teamSubscription,
                                                last4,
                                            }),
                                        );
                                    } else {
                                        window.fj.currentPlan.last4 = last4;
                                        dispatch(mainAction.setCurrentPlan({ last4: last4 }));
                                    }
                                    upgrade();
                                } else if (response.code === 214 || response.code === 335) {
                                    FJUtil.checkLogin(response.code);
                                } else {
                                    // this.isSubmitting = false;
                                    // this.errorTexts.response = response.msg || response.message;
                                    setResponse(response.msg || response.message);
                                }
                            })
                            .catch(error => {
                                // this.isSubmitting = false;
                            });
                    } else {
                        subscription();
                    }
                }
            } else {
                // 印度地区单独采用一套接口
                if (isInOneTimepayment) {
                    subOrUpgradeIN(false);
                } else if (isUpgrade()) {
                    upgrade();
                } else {
                    subscription(false);
                }
            }
        }

        let collectCheckout = () => {
            try {
                let isAnnual = isAnnually;
                let period = isAnnual ? 'annual' : 'monthly';
                let packageName = window.FJGlobalariable.modSubscription.state.selectPlan.package;
                let price = isAnnual ? 12 * planDetail.price.annual : planDetail.price.monthly;
                window.gtag('event', 'begin_checkout', {
                    items: [
                        {
                            item_id: packageName,
                            item_name: packageName,
                            list_name: packageName,
                            item_brand: 'FlexClip',
                            item_category: period,
                            variant: period,
                            list_position: 1,
                            quantity: 1,
                            price: price,
                        },
                    ],
                    coupon: window.FJGlobalariable.modSubscription.state.coupon,
                });
            } catch (e) {}
        };

        collectCheckout();
    };

    const getPricingUri = () => {
        return selectPage === 'team' ? FJUtil.getTeamPricingUri() : FJUtil.getPricingUri();
    };

    const getTitle = () => {
        if (packageType === 'team') {
            if (additionalSeatCount) {
                return FJLocalStore._('Invite people to join your team');
            }
            return FJLocalStore._('Upgrade-title');
        } else {
            return FJLocalStore._('PAY_TITLE');
        }
    };
    const isTeamInvite = () => {
        return typeof additionalSeatCount !== 'undefined' && additionalSeatCount !== null;
    };
    const getTeamPriceDescription = () => {
        if (packageType === 'team') {
            let price = isAnnually ? seatPrice.annual : seatPrice.monthly;

            if (additionalSeatCount) {
                const inviteText = FJLocalStore._('Invite-other-members');
                const inviteTextArr = inviteText.split('xxx');
                const usedUpText = FJLocalStore._('Your-purchased-seats-are-used-up');
                const usedUpTextArr = usedUpText.split('xxx');
                return (
                    <div className={Style.team_price_detail2}>
                        <div className={Style.detail_item}>
                            <div className={Style.icon}>
                                <InlineSVG src={starIcon} />
                            </div>
                            <div className={Style.desc}>
                                {usedUpTextArr[0]}
                                <span>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                    {`${FJUtil.formatNumberThousandth(
                                        FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2),
                                    )}`}
                                </span>
                                {usedUpTextArr[1]}
                            </div>
                        </div>
                        <div className={Style.detail_item}>
                            <div className={Style.icon}>
                                <InlineSVG src={starIcon} />
                            </div>
                            <div className={Style.desc}>
                                {inviteTextArr[0]}
                                <span>{additionalSeatCount}</span>
                                {inviteTextArr[1]}
                            </div>
                        </div>
                    </div>
                );
            } else {
                return (
                    <div className={Style.team_price_detail}>
                        {FJLocalStore._('Include-3-seats').replace(
                            'xxx',
                            `${currentCountryMonetary}${FJUtil.formatNumberThousandth(
                                FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2),
                            )}`,
                        )}
                        {!showTeamSeatsPanel()
                            ? `\n${FJLocalStore._('xxx-seats-in-your-team').replace('xxx', seatCount)}`
                            : null}
                    </div>
                );
            }
        }
    };
    const getShowTeamRestoreMessage = () => {
        return (
            planType === 'team' &&
            teamSubscription &&
            teamSubscription.package !== 'free' &&
            teamSubscription.status !== 'active'
        );
    };
    const getPriceTopTitle = () => {
        return isAnnually
            ? FJLocalStore._('ANNUALLY')
            : FJLocalStore._('MONTHLY').charAt(0).toUpperCase() + FJLocalStore._('MONTHLY').slice(1);
    };

    const canSwitchPeriod = () => {
        // 如果是邀请发起的订阅，不能切换周期
        if (isTeamInvite()) {
            return false;
        }
        // 如果当前套餐是免费的，可以切换
        if (planType === 'team') {
            return teamSubscription?.package === 'free';
        }
        const packageValue = {
            free: 0,
            basic: 1,
            plus: 2,
            business: 3,
        };
        // 如果当前套餐是免费或是月度的
        return (
            currentPlan.package === 'free' ||
            (currentPlan.period === 'monthly' && packageValue[currentPlan.package] < packageValue[selectPage])
        );
    };

    const showTeamSeatsPanel = () => {
        if (selectPage === 'team') {
            if (isTeamInvite()) {
                return false;
            }
            if (teamSubscription?.package === 'free') {
                return true;
            }
        }
        return false;
    };

    const _getRatePrice = (price, discount) => {
        let rate = discount / 100;
        return Math.floor(price * rate * 100) / 100;
    };

    let _getPrice = (price, calculateProportionOrNot = false) => {
        if (window.fjuser) {
            if (!window.fjuser.info.result || window.fjuser.info.policy === 'free') {
                if (discountConf.openDiscount) {
                    let lists = discountConf.lists;
                    if (lists) {
                        let ratePrice;
                        for (const item of lists) {
                            if (!item.package) {
                                ratePrice = _getRatePrice(price, item.discount);
                            }

                            if (item.package && item.package === selectPage) {
                                if (isAnnually) {
                                    if (calculateProportionOrNot) {
                                        ratePrice = _getRatePrice(price, item.discount);
                                    } else {
                                        ratePrice = _getRatePrice(
                                            _getRatePrice(planDetail.price.monthly, item.discount),
                                            item.discountChannel,
                                        );
                                    }
                                } else {
                                    ratePrice = _getRatePrice(price, item.discount);
                                }
                            }
                        }

                        return FJUtil.formatNumberThousandth(
                            FJUtil.preciseMultiply([ratePrice, exchangeRate]).toFixed(2),
                        );
                    }
                }
            }
        }

        return FJUtil.formatNumberThousandth(FJUtil.preciseMultiply([price, exchangeRate]).toFixed(2));
    };

    let countPrice = isAnnually ? _getPrice(price.annual) : _getPrice(price.monthly);

    let getPlanDetailSave = () => {
        let val = planDetail.save;
        if (discountConf.openDiscount) {
            let lists = discountConf.lists;
            if (lists) {
                let tempValue = null;
                for (let i = 0; i < lists.length; i++) {
                    if (
                        lists[i].discountChannel &&
                        lists[i].discountChannel !== 0 &&
                        planDetail.package === lists[i].package
                    ) {
                        tempValue = 100 - lists[i].discountChannel;
                    }
                }
                if (tempValue !== null) {
                    val = tempValue + '%';
                }
            }
        }
        return val;
    };

    return (
        <div className={classNames(Style.bbox, { [Style.bbox_show]: active })}>
            <HeadBrick
                popupType={popupType}
                arrowClick={() => {
                    if (popupType && planType === 'team') {
                        _close();
                    } else {
                        changeStep(1);
                    }
                }}
                closeClick={_close}
            />
            <div className={Style.content}>
                <div className={Style.left_box}>
                    <div className={Style.head}>
                        <img className={Style.banner} src={banner} />
                        <div className={Style.title}>{getTitle()}</div>
                        {canSwitchPeriod() ? (
                            <div className={Style.switch_container}>
                                <div>
                                    {FJLocalStore._('MONTHLY').charAt(0).toUpperCase() +
                                        FJLocalStore._('MONTHLY').slice(1)}
                                </div>
                                <ChangeButton onClick={changePlan} value={isAnnually} />
                                <div>{FJLocalStore._('ANNUALLY')}</div>
                            </div>
                        ) : null}
                        <div className={Style.price_container}>
                            <div className={Style.price_top}>
                                <div className={Style.price_title}>
                                    {FJLocalStore._(name)} {getPriceTopTitle()}
                                </div>
                                {isAnnually && !isTeamInvite() ? (
                                    <div className={Style.price_off}>
                                        {FJLocalStore._('OFF').replace('xxx', getPlanDetailSave())}
                                    </div>
                                ) : null}
                            </div>
                            <div className={Style.period_price}>
                                <span className={Style.currentCountryMonetary}>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                </span>
                                <span className={Style.price}>{countPrice}</span>
                                <span className={Style.unit}>{FJLocalStore._('PAY_UNIT_PRICE')}</span>
                            </div>
                            {getTeamPriceDescription()}
                        </div>
                    </div>
                    <div className={Style.feature_list_container}>
                        {packageType === 'team' ? (
                            <div className={Style.feature_list_head}>
                                {FJLocalStore._('Including-all-business-features')}
                            </div>
                        ) : null}
                        {_drawItem()}
                        <a className={Style.more_btn} href={getPricingUri()} target='_blank'>
                            {FJLocalStore._('See-all-features')}
                        </a>
                    </div>
                    <div className={Style.icon_tip}>
                        <div>
                            <InlineSVG src={payIcon} />
                            <span>{FJLocalStore._('DAY_7')}</span>
                        </div>
                        <div>
                            <InlineSVG src={shieldIcon} />
                            <span>{FJLocalStore._('PAY_SECURE')}</span>
                        </div>
                    </div>
                    <span
                        className={Style.other_faq}
                        dangerouslySetInnerHTML={{
                            __html: faq(),
                        }}
                    />
                </div>

                <div className={Style.right_box}>
                    <div className={Style.pay_title}>{FJLocalStore._('Payment-Details')}</div>

                    {!isUpgrade() && <PayMethod method={payMethod} change={_changePayMethod} />}

                    <div className={Style.card_box} style={payMethod === 'stripe' ? {} : { display: 'none' }}>
                        {showChardInfo() && <CardInfoBrick />}
                        {showChardForm() && (
                            <CreditCardPanel ref={formRef} mode={isInOneTimepayment ? 'payment' : 'subscription'} />
                        )}
                    </div>
                    <div className={Style.response_box}>{response}</div>
                    <div style={{ height: showChardInfo() ? '54px' : payMethod === 'stripe' ? '0' : '30px' }} />
                    {showTeamSeatsPanel() ? (
                        <TeamSeatsPanel seatCount={seatCount} onChangeSeats={onChangeSeats} />
                    ) : null}
                    <CouponBrick
                        countPrice={countPrice}
                        isAnnually={isAnnually}
                        plan={planDetail}
                        step={step}
                        active={active}
                        ref={couponBrickRef}
                    />
                    {getShowTeamRestoreMessage() ? (
                        <div className={Style.team_restore_message}>
                            {FJLocalStore._('Your-subscription-will-be-restored.')}
                        </div>
                    ) : null}
                    <div
                        className={Style.card_btn}
                        style={payMethod === 'stripe' ? {} : { display: 'none' }}
                        onClick={_submitPay}
                    >
                        {FJLocalStore._('BUTTON_PAY_NOW')}
                    </div>
                    <PaypalBtn
                        isAnnually={isAnnually}
                        style={payMethod === 'paypal' ? {} : { display: 'none' }}
                        getPlan={_getPlan}
                        isUpgrade={isUpgrade}
                        paypalOnClick={_onPayClick}
                    />
                    <span
                        className={Style.terms}
                        dangerouslySetInnerHTML={{
                            __html: FJLocalStore._('PAY_TERMS_OF_USE').replace('%s', '/terms.html'),
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default PayPanel;
