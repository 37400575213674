import styles from './FJCreditsPackageView.module.less';
import InlineSvg from 'react-inlinesvg';
import ChooseIcon from '../../../icon/choose.svg';
import classnames from 'classnames';
import FJLocalStore from '../../../store/FJLocalStore';
import FJUtil from '../../../util/FJUtil';
import { useSelector } from 'react-redux';

const baseUrl = 'https://resource.flexclip.com/app/subscription/img/';

const FJCreditsPackageView = props => {
    const { purchase, changeSelectCredit, creditsPayData, pageMethod } = props;
    const selectIndex = creditsPayData.index;
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);

    return (
        <div className={classnames(styles.purchase_box, styles[pageMethod])}>
            {purchase.map((item, index) => {
                const realPrice = FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2);
                const pricePerCredit = (Number(realPrice) / Number(item.base)).toFixed(3);
                return (
                    <li
                        key={index}
                        className={classnames(styles.purchase_item, {
                            [styles.active]: selectIndex === index,
                        })}
                        onClick={() => {
                            changeSelectCredit(index);
                        }}
                    >
                        <div className={styles.outer_box}>
                            <div className={styles.purchase_info}>
                                <div className={styles.left_top}>
                                    <img src={`${baseUrl}Credits-20.webp`} alt='coin' width={20} height={20} />
                                    <span className={styles.purchase_base}>{item.base}</span>
                                </div>
                                <span>
                                    {currentCountryMonetary}
                                    {currentCountryMonetaryISO}
                                    &nbsp;
                                    {FJLocalStore._('dollar-per-credit').replace('xxx', pricePerCredit)}
                                </span>
                            </div>
                            {item.present && (
                                <div className={styles.purchase_remark}>
                                    {FJLocalStore._('bonus') + ' ' + item.present}
                                </div>
                            )}
                            {selectIndex === index && <InlineSvg className={styles.credits_svg} src={ChooseIcon} />}
                        </div>
                    </li>
                );
            })}
        </div>
    );
};
export default FJCreditsPackageView;
