import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Styles from './BaseBox.module.less';
import FJLocalStore from '../../../store/FJLocalStore';
import FJUtil from '../../../util/FJUtil';

export default function BaseBox(props) {
    const { purchase, checkedPurchase, purchaseItemClick, baseUrl, chooseCreditsAndToPayPanel } = props;
    const exchangeRate = useSelector(state => state.mainReducer.exchangeRate);
    const currentCountryMonetary = useSelector(state => state.mainReducer.currentCountryMonetary);
    const currentCountryMonetaryISO = useSelector(state => state.mainReducer.currentCountryMonetaryISO);
    const entry = useSelector(state => state.mainReducer.entry);
    useEffect(() => {
        const fromAITool = !['editor', 'project', 'billing'].some(keyword => entry.includes(keyword));
        FJUtil.ealog('ai tool page revenue window visit', {
            'premium account': 'credits get window',
            ...(fromAITool && { 'ai tool name': entry }),
        });
    }, [entry]);
    
    return (
        <>
            <div className={Styles.left}>
                <p className={Styles.left_title}>{FJLocalStore._('get-ai-credits')}</p>
                <p className={Styles.left_desc}>{FJLocalStore._('enjoy-ai-tools')}</p>
                {purchase.map((item, index) => {
                    const realPrice = FJUtil.preciseMultiply([parseFloat(item.price), exchangeRate]).toFixed(2);
                    const realPriceStr = FJUtil.formatNumberThousandth(realPrice);
                    const pricePerCredit = (Number(realPrice) / Number(item.base)).toFixed(3);
                    return (
                        <div
                            className={`${Styles.list_item} ${checkedPurchase === index ? Styles.active_item : ''}`}
                            key={'list' + index + 1}
                            onClick={() => {
                                purchaseItemClick(index);
                            }}
                        >
                            <div className={Styles.outer_box}>
                                <div className={Styles.item_left}>
                                    <div className={Styles.left_top}>
                                        <img
                                            className={Styles.left_top_icon}
                                            width={26}
                                            height={26}
                                            src={`${baseUrl}Credits-26.webp`}
                                        />
                                        <p className={Styles.left_top_credit}>{item.base}</p>
                                    </div>
                                    <p className={Styles.left_bottom}>
                                        {currentCountryMonetary}
                                        {currentCountryMonetaryISO}
                                        &nbsp;
                                        {FJLocalStore._('dollar-per-credit').replace('xxx', pricePerCredit)}
                                    </p>
                                </div>
                                <div className={Styles.item_right}>
                                    <span>
                                        {currentCountryMonetary}
                                        {currentCountryMonetaryISO}
                                    </span>
                                    <span>{realPriceStr}</span>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <button className={Styles.left_btn} onClick={chooseCreditsAndToPayPanel}>
                    {FJLocalStore._('choose-now')}
                </button>
                <p className={Styles.tip}>{FJLocalStore._('CreditsValidity')}</p>
            </div>
            <div className={Styles.right}>
                <img src={`${baseUrl}new-credit-credit.webp`} alt='credit img' width={460} height={620} />
            </div>
        </>
    );
}
